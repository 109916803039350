import styled from '@emotion/styled';
import { INTERNAL_LINK_TYPE_ID } from '@m2/blueprint-contentful';
import {
	QuickLinkAsset,
	QuickLinkEmail,
	QuickLinkExternal,
} from '@m2/blueprint-ui';
import React from 'react';
import {
	ASSET_LINK_TYPE_ID,
	EMAIL_LINK_TYPE_ID,
	EXTERNAL_LINK_TYPE_ID,
	IQuickLinksSectionEntry,
} from '../../../../contentful';
import { useCommonData } from '../../../../common-data/useCommonData';
import { Color } from '../../../../styles/Color';
import { getUrlForLink } from '../../../../navigation';
import { QuickLinkInternal, QuickLinks } from '../../quick-links';
import { Section } from '../Section';

export interface IQuickLinksSectionProps {
	section: IQuickLinksSectionEntry;
}

export const QuickLinksSection = ({ section }: IQuickLinksSectionProps) => {
	const { pathToPageIdMap } = useCommonData();
	return (
		<Section>
			{section.fields.title && (
				<StyledHeadingWrapper>
					<h1>{section.fields.title}</h1>
				</StyledHeadingWrapper>
			)}
			<QuickLinks
				columns={1}
				style={{ marginBottom: '15px', borderBottom: '0px' }}
			>
				{section.fields.links.map((linkItem) => {
					if (linkItem.contentType === INTERNAL_LINK_TYPE_ID)
						return (
							<QuickLinkInternal
								key={linkItem.id}
								title={linkItem.fields.title}
								url={getUrlForLink(linkItem, pathToPageIdMap)}
							/>
						);

					if (linkItem.contentType === EXTERNAL_LINK_TYPE_ID)
						return (
							<QuickLinkExternal
								key={linkItem.id}
								title={linkItem.fields.title}
								url={getUrlForLink(linkItem, pathToPageIdMap)}
							/>
						);
					if (
						linkItem.contentType === ASSET_LINK_TYPE_ID &&
						linkItem.fields.asset
					)
						return (
							<QuickLinkAsset
								key={linkItem.id}
								title={linkItem.fields.title}
								url={linkItem.fields.asset.fields.file.url}
							/>
						);
					if (linkItem.contentType === EMAIL_LINK_TYPE_ID)
						return (
							<QuickLinkEmail
								key={linkItem.id}
								title={linkItem.fields.title}
								address={linkItem.fields.address}
							/>
						);
					return <></>;
				})}
			</QuickLinks>
		</Section>
	);
};

export const StyledHeadingWrapper = styled.div`
	h1 {
		font-weight: normal;
		line-height: normal;
		color: ${Color.PrimaryDarkGray100};
		font-size: 32px;
	}
`;
