import React from 'react';
import styled from '@emotion/styled';
import { ChevronRightLink, Responsive } from '@m2/blueprint-ui';

import { Color } from '../../../styles/Color';
import { IExternalLinkEntry, IInternalLinkEntry } from '../../../contentful';
import { getUrlForLink } from '../../../navigation';
import { getResponsiveImageUrl } from '../../../utils';
import { formatFullDate } from '../../../i18n/formatFullDate';

export const ITEM_WIDTH = 352;

export const NewsCarouselPreviewItem = ({
	title,
	onClick,
	imageSrc,
	excerpt,
	publicationDate,
	link,
	pathToPageIdMap,
}: {
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
	title: string;
	imageSrc: string;
	excerpt: string;
	publicationDate: string;
	link: IInternalLinkEntry | IExternalLinkEntry;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	pathToPageIdMap: any;
}) => (
	<StyledCarouselItemPreview onClick={onClick}>
		<StyledImage src={getDesktopImageUrl(imageSrc)} alt="" />
		<StyledPublicationDate>
			{formatFullDate(publicationDate)}
		</StyledPublicationDate>
		<StyledCarouselHighlightItemTitle>{title}</StyledCarouselHighlightItemTitle>
		<StyledCarouselHighlightItemExcerpt>
			{excerpt}
		</StyledCarouselHighlightItemExcerpt>
		<ChevronRightLink
			style={{ fontWeight: 'bold' }}
			href={getUrlForLink(link, pathToPageIdMap)}
		>
			Read more
		</ChevronRightLink>
	</StyledCarouselItemPreview>
);

export const NewsCarouselHighlightItem = ({
	title,
	imageSrc,
	excerpt,
	publicationDate,
	link,
	pathToPageIdMap,
}: {
	title: string;
	imageSrc: string;
	excerpt: string;
	publicationDate: string;
	link: IInternalLinkEntry | IExternalLinkEntry;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	pathToPageIdMap: any;
}) => (
	<StyledCarouselItemHighlight>
		<StyledImage src={getDesktopImageUrl(imageSrc)} alt="" />
		<StyledPublicationDate>
			{formatFullDate(publicationDate)}
		</StyledPublicationDate>
		<StyledCarouselHighlightItemTitle>{title}</StyledCarouselHighlightItemTitle>
		<StyledCarouselHighlightItemExcerpt>
			{excerpt}
		</StyledCarouselHighlightItemExcerpt>
		<ChevronRightLink
			style={{ fontWeight: 'bold' }}
			href={getUrlForLink(link, pathToPageIdMap)}
		>
			Read more
		</ChevronRightLink>
	</StyledCarouselItemHighlight>
);

export const NewsCarouselPlaceholderItem = () => (
	<StyledCarouselItemPlaceholder />
);

export const NewsCarouselMobileItem = ({
	title,
	imageSrc,
	publicationDate,
	link,
	pathToPageIdMap,
}: {
	title: string;
	imageSrc: string;
	publicationDate: string;
	link: IInternalLinkEntry | IExternalLinkEntry;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	pathToPageIdMap: any;
}) => (
	<StyledCarouselMobileItem href={getUrlForLink(link, pathToPageIdMap)}>
		<StyledImageMobile src={getMobileImageUrl(imageSrc)} alt="" />
		<StyledMobileItemHeadingWrapper>
			<StyledPublicationDateMobile>
				{formatFullDate(publicationDate)}
			</StyledPublicationDateMobile>
			<StyledItemTitleMobile>{title}</StyledItemTitleMobile>
		</StyledMobileItemHeadingWrapper>
	</StyledCarouselMobileItem>
);

export const StyledCarouselMobileItem = styled.a`
	display: flex;
	width: 100%;
	margin-bottom: 24px;

	:hover {
		cursor: pointer;
		text-decoration: none;
	}
`;

export const StyledImageMobile = styled.img`
	margin-right: 23px;
	width: 120px;
	height: 80px;
	border-radius: 8px;
`;
export const StyledPublicationDateMobile = styled.div`
	font-size: 14px;
	font-weight: normal;
	color: ${Color.PrimaryDarkGray60};
	margin-bottom: 0;
	margin-top: 2px;
	text-transform: uppercase;
`;

export const StyledItemTitleMobile = styled.div`
	font-size: 18px;
	font-weight: normal;
	color: ${Color.PrimaryDarkGray100};
	width: auto;
	max-width: 400px;
	max-height: 54px;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		max-width: 300px;
	}
`;

export const StyledMobileItemHeadingWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		width: 50vw;
	}
`;

export const StyledImage = styled.img`
	width: 352px;
	border-radius: 8px;
	margin-bottom: 24px;
`;

export const StyledPublicationDate = styled.p`
	font-size: 14px;
	font-weight: normal;
	color: ${Color.PrimaryDarkGray60};
	margin-bottom: 0;
	text-transform: uppercase;
`;

export const StyledCarouselHighlightItemTitle = styled.div`
	display: inline-block;
	font-weight: normal;
	font-size: 24px;
	line-height: normal;
	color: ${Color.PrimaryDarkGray100};
	z-index: 2;
	margin-bottom: 7px;
	max-height: 72px;
	max-width: 352px;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StyledCarouselHighlightItemExcerpt = styled.p`
	display: flex;
	font-weight: normal;
	font-size: 16px;
	color: ${Color.PrimaryDarkGray100};
	z-index: 2;
	margin-bottom: 15px;
	max-height: 78px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StyledCarouselItemPreview = styled.div`
	display: flex;
	flex-direction: column;
	width: ${ITEM_WIDTH}px;
	border: 0;
	border-radius: 8px;
	margin-left: 28px;
	margin-right: 28px;
	align-items: flex-start;
	cursor: pointer;
	height: 470px;
	flex: 0 0 ${ITEM_WIDTH}px;

	div img {
		min-width: 100%;
	}
`;

export const StyledCarouselItemHighlight = styled(StyledCarouselItemPreview)``;

export const StyledCarouselItemPlaceholder = styled.div`
	height: 352px;
	width: 470px;
	margin-left: 28px;
	margin-right: 28px;
	display: flex;
	flex-shrink: 0;
`;

function getDesktopImageUrl(imageUrl: string): string {
	return getResponsiveImageUrl(imageUrl, {
		fit: 'fill',
		w: 704,
		h: 460,
	});
}

function getMobileImageUrl(imageUrl: string): string {
	return getResponsiveImageUrl(imageUrl, {
		fit: 'fill',
		w: 240,
		h: 160,
	});
}
