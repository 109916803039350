import React from 'react';
import styled from '@emotion/styled';
import { ParkingAvailabilityColumn } from './ParkingAvailabilityColumn';
import { ParkingOccupancyIndicator } from '../ParkingOccupancyIndicator';
import { Color } from '../../../../styles/Color';

interface IParkingAvailabilityIndicatorProps {
	occupancyPercentage: number | null;
	rawOccupancyPercentage: number | null;
}

export const ParkingAvailabilityIndicator = ({
	occupancyPercentage,
	rawOccupancyPercentage,
}: IParkingAvailabilityIndicatorProps) => (
	<ParkingAvailabilityColumn
		header={getOccupancyHeader(
			occupancyPercentage ?? -1,
			rawOccupancyPercentage ?? -1,
		)}
		aria-label={`${occupancyPercentage}% spots occupied`}
	>
		<ParkingOccupancyIndicator
			occupancyPercentage={occupancyPercentage ?? -1}
			rawOccupancyPercentage={rawOccupancyPercentage ?? -1}
		/>
	</ParkingAvailabilityColumn>
);

const getOccupancyHeader = (
	occupancyPercentage: number,
	rawOccupancyPercentage: number,
) => {
	if (rawOccupancyPercentage === -1) {
		return <>Closed</>;
	}
	if (rawOccupancyPercentage >= 85 && rawOccupancyPercentage <= 92) {
		return (
			<>
				<StyledPercentageHigh>85% - 92%</StyledPercentageHigh> Full
			</>
		);
	}
	if (rawOccupancyPercentage >= 92 && rawOccupancyPercentage <= 100) {
		return (
			<>
				<StyledPercentageFull>92% - 100%</StyledPercentageFull> Full
			</>
		);
	}
	return (
		<>
			<StyledPercentageLow>{occupancyPercentage}%</StyledPercentageLow> Full
		</>
	);
};

const StyledPercentageLow = styled.strong`
	color: ${Color.SecondaryGreen30};
`;
const StyledPercentageHigh = styled.strong`
	color: ${Color.SecondaryYellow100};
`;
const StyledPercentageFull = styled.strong`
	color: ${Color.UtilityR300};
`;
