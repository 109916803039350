import { action, computed, makeObservable, observable } from 'mobx';
import { RemoteDataStore } from '@m2/blueprint-utils';
import { IParkingLocation } from '../types';
import { ParkingAvailabilityType } from '../types/ParkingAvailabilityType';

export class LocationStore extends RemoteDataStore<void, IParkingLocation[]> {
	private _locations?: IParkingLocation[];

	private _filters = new Set<ParkingAvailabilityType>([
		ParkingAvailabilityType.TERMINAL,
	]);

	constructor(private getParkingLocations: () => Promise<IParkingLocation[]>) {
		super();

		makeObservable<LocationStore, '_locations' | '_filters' | 'handleResponse'>(
			this,
			{
				_locations: observable,
				_filters: observable,
				locations: computed,
				toggleFilter: action.bound,
				clear: action,
				handleResponse: action,
			},
		);
	}

	public get locations() {
		if (!this._locations) return [];
		if (!this.filters.size) return this._locations;
		return this._locations.filter(({ type }) => this.filters.has(type));
	}

	public get filters() {
		return this._filters;
	}

	public toggleFilter(filter: ParkingAvailabilityType) {
		if (!this._filters.has(filter)) {
			this._filters.clear();
			this._filters.add(filter);
		}
	}

	public clear(): void {
		this._locations = undefined;
	}

	protected handleResponse(response: IParkingLocation[]): void {
		this._locations = response;
	}

	protected performRequest(): Promise<IParkingLocation[]> {
		return this.getParkingLocations();
	}
}
