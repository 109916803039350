import React, { HTMLAttributes, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import { Color } from '../../../../styles/Color';

interface IParkingAvailabilityColumnProps
	extends HTMLAttributes<HTMLDivElement> {
	children?: ReactNode;
	header?: string | ReactNode;
}

export const ParkingAvailabilityColumn = ({
	header,
	children,
	...props
}: IParkingAvailabilityColumnProps) => (
	<StyledColumn {...props}>
		{header && <StyledColumnHeader>{header}</StyledColumnHeader>}
		<div>{children}</div>
	</StyledColumn>
);

const StyledColumn = styled.div`
	padding: 16px 24px;
	flex-grow: 1;
	flex-basis: 0;
	line-height: 24px;
	color: ${Color.PrimaryBlack};

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: flex;
		flex-direction: column;
		padding: 0 !important;
	}
`;

export const StyledColumnHeader = styled.div`
	color: ${Color.PrimaryDarkGray100};
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 4px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		margin-bottom: 0;
	}
`;
