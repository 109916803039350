import { getPercentageForIndex } from './getPercentageForIndex';

export function getScrollPositionForIndex(
	container: HTMLDivElement,
	index: number,
	totalItems: number,
) {
	const maxScrollPositionX = container.scrollWidth - container.clientWidth;

	return getPercentageForIndex(index, totalItems) * maxScrollPositionX;
}
