import React from 'react';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import { ParkingAvailabilityType } from '../types/ParkingAvailabilityType';
import { ParkingAvailabilityFilterButton } from './ParkingAvailabilityFilterButton';
import { useScrollPosition } from '../../../components/common/header/hooks/useScrollPosition';
import { Color } from '../../../styles/Color';

interface IParkingAvailabilityProps {
	onToggle: (selected: ParkingAvailabilityType) => void;
	activeType?: Set<ParkingAvailabilityType>;
	getTitle: (type: ParkingAvailabilityType) => string;
	clickable: boolean;
}

export const ParkingAvailabilityFilter = ({
	onToggle,
	activeType,
	getTitle,
	clickable = true,
}: IParkingAvailabilityProps) => {
	const scrollPosition = useScrollPosition();

	return (
		<ParkingAvailabilityFilterWrapper
			isScrolledDown={scrollPosition.current > 245}
		>
			{Object.values(ParkingAvailabilityType).map((type) => (
				<ParkingAvailabilityFilterButton
					key={type}
					clickable={clickable}
					active={activeType?.has(type) ?? false}
					onChange={() => onToggle(type)}
					name={getTitle(type)}
				/>
			))}
		</ParkingAvailabilityFilterWrapper>
	);
};

const ParkingAvailabilityFilterWrapper = styled.div<{
	isScrolledDown: boolean;
}>`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-left: 40px;
	padding-right: 40px;
	height: 38px;
	gap: 80px;
	margin-bottom: 48px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		justify-content: space-between;
		padding-left: 0;
		padding-right: 0;
		margin-top: 42px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		gap: 0;
		top: 62px;
		left: 0;
		margin-bottom: 0;
		padding: 16px 16px 8px;
		height: 48px;
		justify-content: space-between;

		position: sticky;
		// make sure z-index is higher than the top bar
		z-index: 2001;
		background-color: ${Color.PrimaryWhite};

		box-shadow: ${(props) =>
			props.isScrolledDown ? `0 2px 4px 0 rgba(0, 0, 0, 0.1)` : `none`};
	}
`;
