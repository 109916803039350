import React from 'react';
import styled from '@emotion/styled';

import { Responsive } from '@m2/blueprint-ui';
import { Section } from '../Section';
import { CarouselContainer } from '../../carousel';
import { ICarouselSectionEntry } from '../../../../contentful';
import { Color } from '../../../../styles/Color';
import { CarouselContainerMobile } from '../../carousel/CarouselContainerMobile';

export interface ICarouselSectionProps {
	section: ICarouselSectionEntry;
}

export const CarouselSection = ({ section }: ICarouselSectionProps) => (
	<Section>
		<StyledHeadingWrapper>
			{section.fields.subtitle && <h2>{section.fields.subtitle}</h2>}
			<h1>{section.fields.title}</h1>
		</StyledHeadingWrapper>
		<FlexWrapper>
			<CarouselContainer section={section} />
		</FlexWrapper>

		<CarouselContainerMobile section={section} />
	</Section>
);

export const FlexWrapper = styled.div`
	display: flex;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

export const StyledHeadingWrapper = styled.div`
	h1 {
		font-weight: normal;
		line-height: normal;
		color: ${Color.PrimaryDarkGray100};
		font-size: 32px;
		margin-bottom: 0px;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			font-size: 24px;
		}
	}

	h2 {
		line-height: normal;
		color: ${Color.PrimaryOrange100};
		font-size: 18px;
		margin-bottom: 0;
	}
`;
