import React from 'react';
import styled from '@emotion/styled';
import {
	CallToAction as BaseCallToAction,
	ICallToActionProps as IBaseCallToActionProps,
} from '@m2/blueprint-ui';
import { ICallToActionTheme } from './ICallToActionTheme';
import { getCallToActionStyle } from './getCallToActionStyle';

interface ICallToActionProps extends IBaseCallToActionProps {
	callToActionTheme: ICallToActionTheme;
	body?: string;
}

export const CustomStyledCallToAction = styled(
	BaseCallToAction,
)<ICallToActionProps>`
	${({ callToActionTheme }) => getCallToActionStyle(callToActionTheme)}
`;

export const CallToAction = ({
	callToActionTheme,
	body,
	...rest
}: ICallToActionProps) => (
	<CustomStyledCallToAction
		callToActionTheme={callToActionTheme}
		body={body}
		inverted
		{...rest}
	/>
);
