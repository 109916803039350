import styled from '@emotion/styled';
import React from 'react';
import { Markdown } from '@m2/blueprint-ui';
import illustration from '../../../assets/dfw-car.svg?reference';

export const ParkingAvailabilityError = ({ message }: { message: string }) => (
	<StyledErrorWrapper>
		<StyledImage src={illustration} alt="Something went wrong..." />
		<Markdown source={message} />
	</StyledErrorWrapper>
);

const StyledErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	max-width: 550px;
	margin-left: auto;
	margin-right: auto;
`;

const StyledImage = styled.img`
	margin-bottom: 24px;
`;
