import React from 'react';
import {
	LinkButton,
	Markdown,
	Responsive,
	StyledLinkButton,
	StyledLinkText,
	UnstyledUl,
} from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import {
	ParkingAvailabilityRow,
	ParkingAvailabilityRowShimmer,
} from './parkingAvailabilityRow/ParkingAvailabilityRow';
import { Color } from '../../../styles/Color';
import { IParkingLocation } from '../types';
import { IExternalLinkEntry, IInternalLinkEntry } from '../../../contentful';
import { getUrlForLink } from '../../../navigation';
import { useCommonData } from '../../../common-data/useCommonData';
import { useTranslation } from '../../../i18n/translate/useTranslation';

interface IParkingAvailabilityLocationsProps {
	title: string;
	description?: string;
	isLoading: boolean;
	locations: IParkingLocation[];
	bookNowLink?: IExternalLinkEntry | IInternalLinkEntry;
}

export const ParkingAvailabilityLocations = ({
	title,
	description,
	locations,
	isLoading,
	bookNowLink,
}: IParkingAvailabilityLocationsProps) => {
	const { t } = useTranslation();
	const { pathToPageIdMap } = useCommonData();
	if (isLoading) return <ParkingAvailabilityRowShimmer />;
	if (locations.length === 0) return null;
	return (
		<StyledParkingAvailabilityLocations>
			<StyledHeader>{title}</StyledHeader>
			<StyledHeaderContentWrapper>
				<Markdown source={description ?? ''} />
				{bookNowLink && (
					<LinkButton href={getUrlForLink(bookNowLink, pathToPageIdMap)}>
						{bookNowLink.fields.title}
					</LinkButton>
				)}
			</StyledHeaderContentWrapper>
			{locations.map((parkingLocation) => (
				<ParkingAvailabilityRow
					key={parkingLocation.name}
					parkingLocation={parkingLocation}
				/>
			))}
			<StyledRateHint>
				{t('parking_availability_rate_disclaimer')}
			</StyledRateHint>
		</StyledParkingAvailabilityLocations>
	);
};

const StyledParkingAvailabilityLocations = styled(UnstyledUl)`
	margin-bottom: 24px;

	p {
		margin-bottom: 16px;
		font-size: 16px;
		color: ${Color.PrimaryBlack};

		max-width: 640px;
	}
`;

const StyledHeaderContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 40px;

	margin-bottom: 24px;

	p {
		margin-bottom: 0 !important;
		align-self: end;
	}

	${StyledLinkButton} {
		a {
			display: flex;
			align-items: center;
			${StyledLinkText} {
				align-self: center;
			}

			@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
				justify-content: space-around;
			}
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		flex-direction: column;
		gap: 16px;
	}
`;

const StyledHeader = styled.h4`
	color: ${Color.PrimaryOrange100};
	margin-bottom: 3px;
	font-size: 24px;
	font-weight: 700;
`;

const StyledRateHint = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: end;
	margin-top: -16px;
`;
