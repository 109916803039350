import React from 'react';
import { IResponsiveImageSource, Responsive } from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import { IImageAsset } from '../../../../contentful';
import { ContactItemContainer, ContactItem } from '../../contact';
import {
	getResponsiveImageUrl,
	IImageOptions,
	getListedEntryKey,
} from '../../../../utils';
import {
	IContactSectionEntry,
	IContactItemEntry,
} from '../../../../contentful/types';
import { Section } from '../Section';
import { Color } from '../../../../styles/Color';
import { useOnClickCardHandler } from '../../cards';

const DEFAULT_IMAGE_BACKGROUND_COLOR = 'ffffff';

export interface IContactSectionProps {
	section: IContactSectionEntry;
}

export const ContactSection = ({ section }: IContactSectionProps) => {
	const defaultLinkTitle = 'Go to website';

	return (
		<Section>
			{section.fields.subtitle && (
				<StyledSectionSubtitle>{section.fields.subtitle}</StyledSectionSubtitle>
			)}
			{section.fields.title && (
				<StyledSectionTitle>{section.fields.title}</StyledSectionTitle>
			)}
			<ContactItemContainer>
				{section.fields.items.map((item, index) =>
					renderContactItem(item, index, defaultLinkTitle),
				)}
			</ContactItemContainer>
		</Section>
	);
};

function renderContactItem(
	item: IContactItemEntry,
	index: number,
	defaultLinkTitle: string,
) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const handleClick = useOnClickCardHandler(item.fields.websiteLink as string);

	const StyledContactCard = styled(ContactItem)`
		cursor: pointer;
	`;
	return (
		<StyledContactCard
			key={getListedEntryKey(item, index)}
			title={item.fields.title}
			imageSource={getContactImageSource(item.fields.image)}
			phoneNumber={item.fields.phoneNumber}
			linkTitle={item.fields.websiteTitle || defaultLinkTitle}
			linkHref={item.fields.websiteLink}
			onClick={handleClick}
		/>
	);
}

export function getContactImageSource(
	image: IImageAsset | undefined,
): IResponsiveImageSource {
	if (!image) {
		return '';
	}

	const { url } = image.fields.file;

	const options: IImageOptions = {
		fit: 'pad',
		bg: `rgb:${DEFAULT_IMAGE_BACKGROUND_COLOR}`,
	};

	return [
		getResponsiveImageUrl(url, { ...options, w: 720, h: 400 }),
		{
			mobile: getResponsiveImageUrl(url, { ...options, w: 200, h: 200 }),
			mobileXL: getResponsiveImageUrl(url, { ...options, w: 480, h: 480 }),
		},
	];
}

export const StyledSectionTitle = styled.h1`
	font-weight: normal;
	line-height: normal;
	color: ${Color.PrimaryDarkGray100};
	font-size: 32px;
	margin-bottom: 40px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 24px;
		font-size: 24px;
	}
`;

export const StyledSectionSubtitle = styled.h2`
	line-height: normal;
	color: ${Color.PrimaryOrange100};
	font-size: 18px;
	margin-bottom: 0;
`;
