import React from 'react';
import { CardsContainer, ICardsContainerProps } from '@m2/blueprint-ui';

export const PhotoGalleryContainer = ({
	children,
	...rest
}: ICardsContainerProps) => (
	<CardsContainer {...rest}>{children}</CardsContainer>
);

PhotoGalleryContainer.defaultProps = {
	columns: [3, { tablet: 2, mobileXL: 1 }],
	titleFontSize: [32, { mobileXL: 24, mobile: 18 }],
	cardOrientation: ['vertical', { mobileXL: 'horizontal' }],
	fullyClickable: true,
};
