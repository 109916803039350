/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Responsive } from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import { getListedEntryKey } from '../../../../utils';
import { Section } from '../Section';
import { Color } from '../../../../styles/Color';
import { PhotoGalleryContainer } from '../../photo-gallery/PhotoGalleryContainer';
import { IPhotoGallerySectionEntry } from '../../../../contentful/types/IPhotoGallerySectionEntry';
import { PhotoGalleryItem } from '../../photo-gallery/PhotoGalleryItem';

export const DEFAULT_IMAGE_BACKGROUND_COLOR = 'ffffff';

export interface IPhotoGallerySectionProps {
	section: IPhotoGallerySectionEntry;
}

export const PhotoGallerySection = ({ section }: IPhotoGallerySectionProps) => (
	<Section>
		{section.fields.subtitle && (
			<StyledSectionSubtitle>{section.fields.subtitle}</StyledSectionSubtitle>
		)}
		{section.fields.title && (
			<StyledSectionTitle>{section.fields.title}</StyledSectionTitle>
		)}
		<PhotoGalleryContainer>
			{section.fields.items.map((item, index) => (
				<PhotoGalleryItem item={item} key={getListedEntryKey(item, index)} />
			))}
		</PhotoGalleryContainer>
	</Section>
);

export const StyledSectionTitle = styled.h1`
	font-weight: normal;
	line-height: normal;
	color: ${Color.PrimaryDarkGray100};
	font-size: 32px;
	margin-bottom: 40px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 24px;
		margin-bottom: 24px;
	}
`;

export const StyledSectionSubtitle = styled.h2`
	line-height: normal;
	color: ${Color.PrimaryOrange100};
	font-size: 18px;
	margin-bottom: 0;
`;
