import React from 'react';
import { IPageSectionEntry } from '../../../contentful';
import { renderSectionComponent } from './renderSectionComponent';

interface ISectionListProps {
	sections: IPageSectionEntry[] | undefined;
}

export const SectionList = ({ sections }: ISectionListProps) => (
	<>
		{sections?.map((section: IPageSectionEntry) =>
			renderSectionComponent(section),
		)}
	</>
);
