import {
	Card,
	StyledCardContent,
	StyledCardPicture,
	Responsive,
	StyledCardTitle,
	IResponsiveImageSource,
	StyledIconLink,
} from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Color } from '../../../styles/Color';

interface ICardProps {
	children?: ReactNode;
	title: string;
	imageSource: IResponsiveImageSource;
	imageAlt?: string;
	className?: string;
}

export const PhotoGalleryCard = styled(Card)<ICardProps>`
	${StyledCardTitle} {
		color: ${Color.PrimaryDarkGray100};
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 4px;
		max-width: fit-content;
		text-overflow: ellipsis;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			margin-bottom: 0;
			font-size: 18px;
		}
	}

	span {
		color: ${Color.PrimaryOrange100};
		font-size: 16px;
		font-weight: bold;
		max-width: fit-content;
		display: block;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			max-width: 60vw;
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
			max-width: 50vw;
		}
	}

	${StyledCardPicture} {
		border-radius: 8px;
		height: 366px;
		width: 320px;
		overflow: hidden;

		img {
			object-fit: fill;
			border-radius: 8px;
			height: 100%;
			margin-right: 0px;

			@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
				width: 100%;
				margin-right: 0;
			}
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			height: 114px;
			width: 100px;
			min-width: 80px;
		}
	}

	${StyledCardContent} {
		margin-left: 0px;
		margin-right: 0px;
		width: 320px;
		text-overflow: ellipsis;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			margin-left: 16px;
			margin-right: 0;
		}
	}

	${StyledIconLink} {
		display: none;
	}
`;
