import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
	Button,
	ChevronLeftIcon,
	ChevronRightIcon,
	Markdown,
	Responsive,
} from '@m2/blueprint-ui';
import {
	NewsCarouselHighlightItem,
	NewsCarouselPlaceholderItem,
	NewsCarouselPreviewItem,
	NewsCarouselMobileItem,
} from './NewsCarouselItem';
import { INewsSectionEntry } from '../../../contentful';
import { Color } from '../../../styles/Color';
import { INewsItemEntry } from '../../../contentful/types/INewsItemEntry';
import { useCommonData } from '../../../common-data/useCommonData';
import 'scroll-behavior-polyfill';
import { getScrollPositionForIndex } from '../carousel/utils/getScrollPositionForIndex';
import { getScrollPercentage } from '../carousel/utils/getScrollPercentage';

export const NewsCarouselContainer = ({
	section,
}: {
	section: INewsSectionEntry;
}) => {
	const { items } = section.fields;
	const [indexIsHighlighted, setIndexIsHighlighted] = useState<number>(0);
	const [carouselItems, setCarouselItems] = useState<INewsItemEntry[]>(items);
	const [canScrollLeft, setCanScrollLeft] = useState<boolean>(true);
	const [canScrollRight, setCanScrollRight] = useState<boolean>(true);

	const containerRef = useRef<HTMLDivElement>(null);
	const hasClicked = useRef(false);
	const { pathToPageIdMap } = useCommonData();

	const scrollFunction = useCallback(() => {
		const container = containerRef.current;
		if (!container) {
			return;
		}

		const percentage = getScrollPercentage(container);

		setCanScrollLeft(percentage > 0);
		setCanScrollRight(percentage < 1);
	}, [setIndexIsHighlighted, items]);

	useEffect(() => {
		const container = containerRef.current;

		if (container) {
			container.addEventListener('scroll', scrollFunction);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', scrollFunction);
			}
		};
	}, [scrollFunction, containerRef]);

	const scrollToIndex = () => {
		const container = containerRef.current;
		if (!container) {
			return;
		}

		const scrollPositionX = getScrollPositionForIndex(
			container,
			indexIsHighlighted,
			items.length,
		);

		container.scrollTo(scrollPositionX, 0);
	};

	useEffect(() => {
		scrollToIndex();
	}, [indexIsHighlighted]);

	useEffect(() => {
		setCarouselItems(items);
	}, [items]);

	function handleClicked() {
		hasClicked.current = true;

		setTimeout(() => {
			hasClicked.current = false;
		}, 300);
	}

	return (
		<StyledCarouselContainer>
			<StyledItemsContainerDesktop>
				<StyledInfoSection>
					<StyledInfoSectionSubtitle>
						{section.fields.subtitle}
					</StyledInfoSectionSubtitle>
					<StyledInfoSectionTitle>
						{section.fields.title}
					</StyledInfoSectionTitle>
					<StyledInfoSectionBody>
						{section.fields.body && <Markdown source={section.fields.body} />}
						<StyledOverlay />
					</StyledInfoSectionBody>
				</StyledInfoSection>
				<StyledButtonLeft>
					<Button
						onClick={() => {
							setIndexIsHighlighted(indexIsHighlighted - 1);
							handleClicked();
						}}
						disabled={!canScrollLeft}
					>
						<ChevronLeftIcon variation="inverted" />
					</Button>
				</StyledButtonLeft>
				<ItemsContainer ref={containerRef}>
					{carouselItems.map((carouselItem, index: number) => {
						const isHighlighted = indexIsHighlighted === index;

						if (isHighlighted) {
							return (
								<NewsCarouselHighlightItem
									imageSrc={carouselItem.fields.image.fields.file.url}
									title={carouselItem.fields.title}
									excerpt={carouselItem.fields.excerpt}
									publicationDate={carouselItem.fields.publicationDate}
									link={carouselItem.fields.link}
									pathToPageIdMap={pathToPageIdMap}
									key={Math.random()}
								/>
							);
						}

						return (
							<NewsCarouselPreviewItem
								onClick={() => setIndexIsHighlighted(index)}
								imageSrc={carouselItem.fields.image.fields.file.url}
								title={carouselItem.fields.title}
								excerpt={carouselItem.fields.excerpt}
								publicationDate={carouselItem.fields.publicationDate}
								link={carouselItem.fields.link}
								pathToPageIdMap={pathToPageIdMap}
								key={Math.random()}
							/>
						);
					})}
					<NewsCarouselPlaceholderItem key={Math.random()} />
				</ItemsContainer>
				<StyledGradient />
				<StyledButtonRight>
					<Button
						onClick={() => {
							setIndexIsHighlighted(indexIsHighlighted + 1);
							handleClicked();
						}}
						disabled={!canScrollRight}
					>
						<ChevronRightIcon variation="inverted" />
					</Button>
				</StyledButtonRight>
			</StyledItemsContainerDesktop>

			<StyledItemsContainerMobile>
				<StyledHeadingWrapper>
					{section?.fields.subtitle && <h2>{section.fields.subtitle}</h2>}
					{section?.fields.title && <h1>{section.fields.title}</h1>}
				</StyledHeadingWrapper>
				{items.map(({ fields }) => (
					<NewsCarouselMobileItem
						publicationDate={fields.publicationDate}
						imageSrc={fields.image.fields.file.url}
						title={fields.title}
						link={fields.link}
						pathToPageIdMap={pathToPageIdMap}
						key={Math.random()}
					/>
				))}
			</StyledItemsContainerMobile>
		</StyledCarouselContainer>
	);
};

const StyledHeadingWrapper = styled.div`
	h1 {
		font-weight: normal;
		line-height: normal;
		color: ${Color.PrimaryDarkGray100};
		font-size: 32px;
		margin-bottom: 40px;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			font-size: 24px;
		}
	}

	h2 {
		line-height: normal;
		color: ${Color.PrimaryOrange100};
		font-size: 18px;
		margin-bottom: 0;
	}
`;

const ItemsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	position: relative;
	width: 920px;
	height: 470px;
	overflow: hidden;
	flex-grow: 0;
	overflow-x: scroll;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	padding-left: 12px;
	left: -28px;

	-ms-overflow-style: none;
	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}
`;

const StyledGradient = styled.div`
	height: 470px;
	width: 120px;
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
	z-index: 2;
	position: relative;
	right: 148px;

	@media (max-width: 1440px) {
		position: absolute;
		right: 0px;
	}
`;

const StyledButtonRight = styled.div`
	button > svg {
		margin-left: 0;
		transform: translate(1px, 1px);
	}
	z-index: 3;
	position: relative;
	right: 200px;
	button {
		display: flex;
		align-items: center;
	}

	@media (max-width: 1440px) {
		position: absolute;
		right: 35px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

const StyledCarouselContainer = styled.div`
	display: flex;
	flex-direction: row;
	z-index: 0;
`;

const StyledItemsContainerDesktop = styled.div`
	display: flex;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 40px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;
const StyledOverlay = styled.div`
	position: absolute;
	bottom: 0;
	height: 90px;
	width: 100%;
	background: linear-gradient(180deg, transparent 0%, #e6e6e6 90%);
`;

const StyledInfoSection = styled.div`
	position: relative;
	height: 555px;
	width: 400px;
	margin-right: 28px;
	background-color: ${Color.PrimaryLightGray};
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	padding-top: 40px;
	padding-bottom: 40px;
	padding-right: 35px;
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 320px;
	}
`;

const StyledInfoSectionSubtitle = styled.h2`
	font-size: 18px;
	font-weight: bold;
	color: ${Color.PrimaryOrange100};
`;

const StyledInfoSectionTitle = styled.h1`
	font-size: 32px;
	font-weight: normal;
	color: ${Color.PrimaryDarkGray100};
	margin-bottom: 16px;
	max-width: 318px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledInfoSectionBody = styled.div`
	font-size: 16px;
	font-weight: normal;
	color: ${Color.PrimaryDarkGray100};
	max-width: 384px;
	max-height: 360px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledItemsContainerMobile = styled.div`
	display: none;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-top: 60px;
		padding-bottom: 60px;
		padding-right: 24px;
		background-color: ${Color.PrimaryLightGray};

		a:last-of-type {
			margin-bottom: 0px;
		}
	}
`;

const StyledButtonLeft = styled.div`
	button > svg {
		margin-left: 0;
		transform: translate(-1px, 1px);
	}
	z-index: 1;
	position: absolute;
	transform: translateX(370px);

	button {
		display: flex;
		align-items: center;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		transform: translateX(290px);
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;
