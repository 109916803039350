import React from 'react';
import { Markdown, IResponsiveImageSource, Responsive } from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import {
	ICardSectionEntry,
	ICardItemEntry,
	IImageAsset,
} from '../../../../contentful';
import { Section } from '../Section';
import { CardsContainer } from '../../cards/CardsContainer';
import { getUrlForLink } from '../../../../navigation';
import { getResponsiveImageUrl, getListedEntryKey } from '../../../../utils';
import { useCommonData } from '../../../../common-data/useCommonData';
import { Card } from '../../cards/BaseCard';
import { Color } from '../../../../styles/Color';

export interface ICardSectionProps {
	section: ICardSectionEntry;
}

export const CardSection = ({ section }: ICardSectionProps) => {
	const { pathToPageIdMap } = useCommonData();
	return (
		<Section>
			<StyledHeadingWrapper>
				{section?.fields.subtitle && <h2>{section.fields.subtitle}</h2>}
				{section?.fields.title && <h1>{section.fields.title}</h1>}
			</StyledHeadingWrapper>
			<CardsContainer
				fullyClickable
				columns={[
					getDesktopColumnCount(section),
					{
						tablet: 2,
						mobile: 1,
					},
				]}
				cardOrientation={['vertical', { mobile: 'horizontal' }]}
				childrenHidden={[false, { mobile: true }]}
				linkHidden={[false, { mobile: true }]}
				titleFontSize={[
					32,
					{
						tablet: 24,
						mobile: 18,
					},
				]}
			>
				{section.fields.items.map((item: ICardItemEntry, index: number) =>
					renderCardItem(item, index, pathToPageIdMap),
				)}
			</CardsContainer>
		</Section>
	);
};

function getDesktopColumnCount(section: ICardSectionEntry) {
	const preferredColumnCount = section.fields.columns ?? 3;
	const totalItemCount = section.fields.items.length;

	// For desktop, if there are less items then columns, then use the item length as columns,
	// with a minimum of 2 columns (i.e. 1 item will be rendering in a 2-column layout).
	return Math.max(Math.min(preferredColumnCount, totalItemCount), 2);
}

function renderCardItem(
	item: ICardItemEntry,
	index: number,
	pathToPageIdMap: IPathToPageIdMap,
) {
	if (!item.fields.link) {
		return undefined;
	}

	const linkHref = getUrlForLink(item.fields.link, pathToPageIdMap);

	if (!linkHref) {
		return undefined;
	}

	return (
		<Card
			key={getListedEntryKey(item, index)}
			title={item.fields.title}
			imageSource={getCardImageSource(item.fields.image)}
			linkTitle={item.fields.link && item.fields.link.fields.title}
			linkHref={linkHref as string}
		>
			{item.fields.body && (
				<StyledBodyWrapper>
					<Markdown source={item.fields.body} />
				</StyledBodyWrapper>
			)}
		</Card>
	);
}

function getCardImageSource(
	image: IImageAsset | undefined,
): IResponsiveImageSource {
	if (!image) {
		return '';
	}

	const { url } = image.fields.file;

	return [
		getResponsiveImageUrl(url, { w: 720, h: 400, fit: 'thumb' }),
		{
			mobile: getResponsiveImageUrl(url, { w: 240, h: 240, fit: 'thumb' }),
		},
	];
}

export const StyledHeadingWrapper = styled.div`
	margin-bottom: 40px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 24px;
	}

	h1 {
		font-weight: normal;
		line-height: normal;
		color: ${Color.PrimaryDarkGray100};
		font-size: 32px;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			font-size: 24px;
		}
	}

	h2 {
		line-height: normal;
		color: ${Color.PrimaryOrange100};
		font-size: 18px;
		margin-bottom: 0;
	}
`;

export const StyledBodyWrapper = styled.div`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;
