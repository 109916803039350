import React from 'react';
import styled from '@emotion/styled';
import { QuickLinkItem, Responsive } from '@m2/blueprint-ui';
import { IColumnSectionEntry } from '../../../../contentful';
import { Row } from '../../row/Row';
import { Section } from '../Section';
import { Color } from '../../../../styles/Color';
import { SectionList } from '../SectionList';

export interface IColumnSectionProps {
	section: IColumnSectionEntry;
}

export const ColumnSection = ({ section }: IColumnSectionProps) => (
	<Section>
		{section.fields?.subtitle && (
			<StyledSubtitle>{section.fields.subtitle}</StyledSubtitle>
		)}
		<Row>
			<StyledColumn>
				{section.fields?.leftColumnTitle && (
					<div>
						<StyledColumnTitle>
							{section.fields.leftColumnTitle}
						</StyledColumnTitle>
						<hr />
					</div>
				)}
				<SectionList sections={section.fields.leftColumn} />
			</StyledColumn>
			<StyledColumn>
				{section.fields?.rightColumnTitle && (
					<div>
						<StyledColumnTitle>
							{section.fields.rightColumnTitle}
						</StyledColumnTitle>
						<hr />
					</div>
				)}

				<SectionList sections={section.fields.rightColumn} />
			</StyledColumn>
		</Row>
	</Section>
);

export const StyledColumn = styled.div`
	div > hr {
		margin-bottom: 0px;
	}

	${QuickLinkItem}:first-of-type {
		a {
			padding-top: 0px;
		}
	}
`;

export const StyledSubtitle = styled.h2`
	line-height: normal;
	color: ${Color.PrimaryOrange100};
	font-size: 18px;
	margin-bottom: 6px;
`;

export const StyledColumnTitle = styled.h2`
	font-size: 32px;
	color: ${Color.PrimaryDarkGray100};
	font-weight: normal;
	margin-bottom: 24px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 24px;
		margin-bottom: 8px;
	}
`;
