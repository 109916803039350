import React from 'react';
import styled from '@emotion/styled';
import { IParkingAvailabilitySectionEntry } from '../../../../contentful/types/IParkingAvailabilitySectionEntry';
import { ParkingAvailability } from '../../../../parking/availability/components/ParkingAvailability';
import { useLocationStore } from '../../../../parking/availability/hooks/useLocationStore';
import { Section } from '../Section';

export interface IParkingAvailabilitySectionProps {
	section: IParkingAvailabilitySectionEntry;
}

export const ParkingAvailabilitySection = ({
	section,
}: IParkingAvailabilitySectionProps) => {
	const locationStore = useLocationStore();

	return (
		<StyledSection>
			<ParkingAvailability store={locationStore} section={section.fields} />
		</StyledSection>
	);
};

const StyledSection = styled(Section)`
	margin: 0 !important;
	min-width: 100%;
`;
