import React from 'react';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import { Color } from '../../../styles/Color';

interface IButtonProps {
	active: boolean;
	onChange: (active: boolean) => void;
	name: string;
	clickable: boolean;
}

export const ParkingAvailabilityFilterButton = ({
	active,
	onChange,
	name,
	clickable = true,
}: IButtonProps) => (
	<StyledParkingAvailabilityFilterButton
		active={active}
		onClick={() => onChange(!active)}
		clickable={clickable}
	>
		{name}
	</StyledParkingAvailabilityFilterButton>
);

const StyledParkingAvailabilityFilterButton = styled.div<{
	active: boolean;
	clickable: boolean;
}>`
	border-bottom: ${(props) =>
		props.active ? `solid 2px ${Color.PrimaryOrange100}` : 'none'};
	margin-right: 8px;
	cursor: pointer;
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
	color: ${(props) =>
		props.active ? `${Color.PrimaryBlack}` : `${Color.PrimaryDarkGray100}`};
	pointer-events: ${(props) => (props.clickable ? 'auto' : 'none')};

	&:hover {
		border-bottom: solid 2px ${Color.PrimaryOrange100};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 18px;
		line-height: 19px;
	}
`;
