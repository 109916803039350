import { useCallback } from 'react';
import { useCommonData } from '../../../../common-data/useCommonData';
import { ICarouselItemEntry } from '../../../../contentful';
import { useNavigate, getUrlForLink } from '../../../../navigation';

export function useItemClickHandler() {
	const { pathToPageIdMap } = useCommonData();
	const navigate = useNavigate();

	return useCallback((item: ICarouselItemEntry) => {
		const links = item.fields.content?.fields.links;

		// NB: In case an item has multiple links, the first is considered the target link
		const targetLink = links && links[0];

		const url = targetLink
			? getUrlForLink(targetLink, pathToPageIdMap)
			: undefined;

		if (url) {
			navigate(url);
		}
	}, []);
}
