import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Responsive, TagButton, TagButtonWrap } from '@m2/blueprint-ui';
import { ITabItemEntry, ITabSectionEntry } from '../../../../contentful';
import { Section } from '../Section';
import { SectionList } from '../SectionList';

export interface ITabSectionProps {
	section: ITabSectionEntry;
}

export const TabSection = ({ section }: ITabSectionProps) => {
	const [tabActive, setTabActive] = useState<null | ITabItemEntry>(
		section.fields.items[0],
	);

	return (
		<Section>
			<TabItemsWrap>
				{section.fields.items.map((tabItem: ITabItemEntry) => (
					<TabItemWrap key={tabItem.id}>
						<TagButton
							onClick={() => setTabActive(tabItem)}
							active={tabActive?.id === tabItem.id}
						>
							{tabItem.fields.title}
						</TagButton>
					</TabItemWrap>
				))}
			</TabItemsWrap>
			<StyledSectionWrap>
				{tabActive && <SectionList sections={tabActive.fields.content} />}
			</StyledSectionWrap>
		</Section>
	);
};

export const TabItemWrap = styled.div`
	margin-bottom: 24px;
	margin-right: 24px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 10px;
		margin-right: 10px;
	}
`;

export const TabItemsWrap = styled(TagButtonWrap)`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;
`;

export const StyledSectionWrap = styled.div`
	${Section} {
		margin-left: 0;
	}
`;
