import React, { ReactNode } from 'react';
import { Row as BaseRow, IRowProps as BaseRowProps } from '@m2/blueprint-ui';
import styled from '@emotion/styled';

export const CustomRow = styled(BaseRow)`
	section {
		margin: 0;
	}
`;

interface IRowProps extends BaseRowProps {
	children: ReactNode;
}

export const Row = ({ children }: IRowProps) => (
	<CustomRow columns={[2, { tablet: 1 }]}>{children}</CustomRow>
);
