import React from 'react';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import { ParkingAvailabilityColumn } from './ParkingAvailabilityColumn';
import { Color } from '../../../../styles/Color';

interface IParkingAvailabilityOpenProps {
	isOpen: boolean;
}

export const ParkingAvailabilityOpen = ({
	isOpen,
}: IParkingAvailabilityOpenProps) => {
	const isMobileXL = Responsive.useMatchesBreakpoint('tablet');
	return (
		<>
			{!isMobileXL ? (
				<>
					<ParkingAvailabilityColumn header="Open/Closed">
						<StyledOpenIndicator isOpen={isOpen}>
							{isOpen ? 'Open' : 'Closed'}
						</StyledOpenIndicator>
					</ParkingAvailabilityColumn>
				</>
			) : (
				<StyledOpenIndicator isOpen={isOpen}>
					{isOpen ? 'Open' : 'Closed'}
				</StyledOpenIndicator>
			)}
		</>
	);
};

const StyledOpenIndicator = styled.div<{ isOpen: boolean }>`
	display: flex;
	max-width: fit-content;
	padding: 4px 12px 6px 12px;
	height: 31px;
	border-radius: 8px;
	align-items: center;
	font-weight: 700;
	font-size: 16px;
	color: ${({ isOpen }) =>
		isOpen ? Color.PrimaryBlack : 'rgba(217, 60, 0, 1)'};
	background-color: ${({ isOpen }) =>
		isOpen ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 236, 229, 1)'};

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		font-weight: 400;
		padding: 2px 6px 3px 6px;
		height: 22px;
		font-size: 14px;
		border-radius: 4px;
	}
`;
