import React from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Responsive } from '@m2/blueprint-ui';
import { ParkingAvailabilityColumn } from './ParkingAvailabilityColumn';
import Elevator from '../../../../assets/icons/elevator.svg';
import { Color } from '../../../../styles/Color';

interface IParkingAvailabilityElevatorProps {
	elevatorAvailable: boolean;
}

export const ParkingAvailabilityElevator = ({
	elevatorAvailable,
}: IParkingAvailabilityElevatorProps) => (
	<StyledParkingAvailabilityColumn
		isElevatorAvailable={elevatorAvailable}
		header="Elevator"
	>
		<StyledElevatorWrapper>
			<StyledElevator role="img" elevatorAvailable={elevatorAvailable} />
			{elevatorAvailable ? 'Yes' : 'No'}
		</StyledElevatorWrapper>
	</StyledParkingAvailabilityColumn>
);

const StyledElevatorWrapper = styled.div`
	margin-top: 7px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		margin-top: 4px;
	}
`;
const StyledParkingAvailabilityColumn = styled(ParkingAvailabilityColumn)<{
	isElevatorAvailable: boolean;
}>`
	svg {
		path {
			fill: ${Color.PrimaryDarkGray100} !important;
		}
	}
	opacity: ${({ isElevatorAvailable }) => (isElevatorAvailable ? '1' : '0')};
`;
const StyledElevator = styled(Elevator, {
	shouldForwardProp: (prop) => isPropValid(prop),
})<{ elevatorAvailable: boolean }>`
	vertical-align: bottom;
	margin-right: 6px;
`;
