import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';

import { ICarouselItemEntry, ICarouselSectionEntry } from '../../../contentful';
import {
	CarouselItem,
	CarouselPlaceholderItem,
	HIGHLIGHT_ITEM_HEIGHT,
} from './CarouselItem';
import {
	HighlightSection,
	StyledImageWrapperMobile,
} from '../sections/components/HighlightSection';
import 'scroll-behavior-polyfill';
import { getListedEntryKey } from '../../../utils';
import { getIndexForScrollPosition } from './utils/getIndexForScrollPosition';
import { useItemClickHandler } from './utils/useClickHandler';

export const CarouselContainerMobile = ({
	section,
}: {
	section: ICarouselSectionEntry;
}) => {
	const [indexIsHighlighted, setIndexIsHighlighted] = useState<number>(0);
	const containerRef = useRef<HTMLDivElement>(null);
	const handleClickItem = useItemClickHandler();

	const { items } = section.fields;

	const scrollFunction = useCallback(() => {
		const container = containerRef.current;

		if (!container) {
			return;
		}

		const currentItemIndex = getIndexForScrollPosition(container, items.length);

		setIndexIsHighlighted(currentItemIndex);
	}, [setIndexIsHighlighted, items]);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.addEventListener('scroll', scrollFunction);
		}

		return () => {
			if (containerRef.current) {
				containerRef.current.removeEventListener('scroll', scrollFunction);
			}
		};
	}, [scrollFunction, containerRef]);

	return (
		<StyledCarouselContainer>
			<StyledScrollContainer ref={containerRef}>
				{items.map((item: ICarouselItemEntry, index: number) => {
					const { image, title, icon } = item.fields ?? {};

					return (
						<CarouselItem
							key={getListedEntryKey(item, index)}
							onClick={() => {
								handleClickItem(item);
							}}
							imageSrc={image?.fields.file.url}
							title={title}
							iconSrc={icon?.fields.file.url}
							isHighlighted={index === indexIsHighlighted}
						/>
					);
				})}
				<CarouselPlaceholderItem />
			</StyledScrollContainer>
			<HighlightSection
				section={section.fields.items[indexIsHighlighted].fields.content}
			/>
		</StyledCarouselContainer>
	);
};

export const StyledScrollContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	left: 50%;
	transform: translateX(-50%);
	width: 100vw;
	min-height: ${HIGHLIGHT_ITEM_HEIGHT}px;
	padding: 40px 0;
	overflow-x: scroll;
	overflow-y: hidden;
	scroll-behavior: smooth;

	-ms-overflow-style: none;
	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}
`;

export const StyledCarouselContainer = styled.div`
	display: none;

	${StyledImageWrapperMobile} {
		display: none;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: flex;
		flex-direction: column;

		section {
			margin: 0;
		}
	}
`;

export const StyledHighlightSection = styled.span`
	display: flex;
	color: black;
`;
