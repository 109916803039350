import { getIndexForPercentage } from './getIndexForPercentage';
import { getScrollPercentage } from './getScrollPercentage';

export function getIndexForScrollPosition(
	container: HTMLDivElement,
	totalItems: number,
) {
	const percentage = getScrollPercentage(container);

	return getIndexForPercentage(percentage, totalItems);
}
