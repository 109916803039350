import React, { useState } from 'react';
import { IResponsiveImageSource, Markdown } from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import { IImageAsset } from '../../../contentful';
import { getResponsiveImageUrl, IImageOptions } from '../../../utils';
import { PhotoGalleryCard } from './PhotoGalleryCard';
import { Dialog } from '../dialog';
import { IPhotoGalleryItemEntry } from '../../../contentful/types/IPhotoGalleryItemEntry';
import { DEFAULT_IMAGE_BACKGROUND_COLOR } from '../sections/components/PhotoGallerySection';
import { Color } from '../../../styles/Color';

interface IPhotoGalleryItemProps {
	item: IPhotoGalleryItemEntry;
}
export const PhotoGalleryItem = ({ item }: IPhotoGalleryItemProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);

	return (
		<>
			<PhotoGalleryItemWrapper>
				<PhotoGalleryCard
					title={item.fields.title}
					imageSource={getPhotoGalleryItemImageSource(item.fields.image)}
					linkHref=""
					onClick={open}
				>
					<span>{item.fields.subtitle}</span>
				</PhotoGalleryCard>
			</PhotoGalleryItemWrapper>
			<PhotoGalleryItemDialog item={item} isOpen={isOpen} close={close} />
		</>
	);
};

interface IPhotoGalleryItemDialogProps {
	item: IPhotoGalleryItemEntry;
	isOpen: boolean;
	close: () => void;
}

const PhotoGalleryItemDialog = ({
	item,
	isOpen,
	close,
}: IPhotoGalleryItemDialogProps) => (
	<StyledDialog
		isOpen={isOpen}
		onDismiss={close}
		title={
			<>
				{item.fields.title}
				<strong>{item.fields.subtitle}</strong>
			</>
		}
		ariaLabel={item.fields.title}
		hasGradient
	>
		<DialogBodyWrapper>
			{item.fields?.body && <Markdown source={item.fields.body} />}
		</DialogBodyWrapper>
	</StyledDialog>
);

const StyledDialog = styled(Dialog)`
	h2 {
		font-size: 24px;
		font-weight: bold;
		color: ${Color.PrimaryDarkGray100};
		margin-bottom: 0;

		strong {
			display: block;
			font-size: 16px;
			font-weight: bold;
			color: ${Color.PrimaryOrange100};
			line-height: 1.63;
		}
	}
`;

const PhotoGalleryItemWrapper = styled.div`
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
`;

function getPhotoGalleryItemImageSource(
	image: IImageAsset | undefined,
): IResponsiveImageSource {
	if (!image) {
		return '';
	}

	const { url } = image.fields.file;

	const options: IImageOptions = {
		fit: 'thumb',
		bg: `rgb:${DEFAULT_IMAGE_BACKGROUND_COLOR}`,
		r: 8,
	};

	return [
		getResponsiveImageUrl(url, { ...options, w: 320, h: 360 }),
		{
			mobile: getResponsiveImageUrl(url, {
				...options,
				w: 160,
				h: 200,
			}),
			mobileXL: getResponsiveImageUrl(url, {
				...options,
				w: 480,
				h: 480,
			}),
			desktopXL: getResponsiveImageUrl(url, {
				...options,
				w: 640,
				h: 732,
			}),
		},
	];
}

const DialogBodyWrapper = styled.div`
	margin-bottom: 30px;
`;
