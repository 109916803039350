import React from 'react';
import { Iframe } from '@m2/blueprint-ui';
import { observer } from 'mobx-react';
import { Section } from '../Section';
import { IIFrameSectionEntry } from '../../../../contentful/types/IIFrameSectionEntry';
import { useCookieConsentStore } from '../../../../cookie-consent/useCookieConsentStore';

export interface IFrameSectionProps {
	section: IIFrameSectionEntry;
}

export const IFrameSection = observer(({ section }: IFrameSectionProps) => {
	const { cookieConsentStore } = useCookieConsentStore();

	if (!cookieConsentStore.hasConsent) {
		return null;
	}

	return (
		<Section>
			<Iframe
				url={section.fields.url}
				maxWidth={section.fields.width}
				maxHeight={section.fields.height}
			/>
		</Section>
	);
});
