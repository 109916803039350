import React from 'react';
import styled from '@emotion/styled';
import { Responsive, UnstyledLi } from '@m2/blueprint-ui';
import {
	ParkingAvailabilityColumn,
	StyledColumnHeader,
} from './ParkingAvailabilityColumn';
import { IParkingLocationSection } from '../../types';
import { ParkingAvailabilityCharging } from './ParkingAvailabilityCharging';
import { ParkingAvailabilityIndicator } from './ParkingAvailabilityIndicator';
import { ParkingAvailabilityElevator } from './ParkingAvailabilityElevator';
import { ParkingAvailabilityOpen } from './ParkingAvailabilityOpen';
import { Color } from '../../../../styles/Color';

interface IParkingSectionRowProps {
	parkingSection: IParkingLocationSection;
}

export const ParkingSectionRow = ({
	parkingSection: {
		name,
		chargingAvailable,
		occupancyPercentage,
		rawOccupancyPercentage,
		elevator,
		open,
	},
}: IParkingSectionRowProps) => {
	const isMobileXL = Responsive.useMatchesBreakpoint('tablet');
	return (
		<>
			{!isMobileXL ? (
				<StyledRow>
					<StyledSectionColumn header="Section">
						<StyledTerminalName>{name}</StyledTerminalName>
					</StyledSectionColumn>
					<ParkingAvailabilityOpen isOpen={open} />
					<ParkingAvailabilityIndicator
						rawOccupancyPercentage={open ? rawOccupancyPercentage : null}
						occupancyPercentage={open ? occupancyPercentage : null}
					/>
					<ParkingAvailabilityCharging chargingAvailable={chargingAvailable} />
					<ParkingAvailabilityElevator elevatorAvailable={elevator} />
				</StyledRow>
			) : (
				<StyledMobileColumn>
					<StyledMobileRow isSpaceBetween>
						<StyledTerminalName>{name}</StyledTerminalName>
						<ParkingAvailabilityOpen isOpen={open} />
					</StyledMobileRow>
					<StyledMobileRow>
						<ParkingAvailabilityIndicator
							occupancyPercentage={open ? occupancyPercentage : null}
							rawOccupancyPercentage={open ? rawOccupancyPercentage : null}
						/>
						<StyledMobileRow>
							<ParkingAvailabilityCharging
								chargingAvailable={chargingAvailable}
							/>
							<ParkingAvailabilityElevator elevatorAvailable={elevator} />
						</StyledMobileRow>
					</StyledMobileRow>
				</StyledMobileColumn>
			)}
		</>
	);
};

const StyledRow = styled(UnstyledLi)`
	display: flex;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: flex;
		flex-direction: column;
		padding: 16px;
		border-bottom: 2px solid ${Color.PrimaryLightGray50};
	}

	background-color: ${Color.PrimaryWhite};
	border-radius: 8px;
	margin-bottom: 2px;

	&:first-child,
	&:not(:last-child) {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	&:not(:first-child):not(:last-child) {
		border-radius: 4px;
	}

	&:last-child,
	&:not(:first-child) {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
`;

const StyledSectionColumn = styled(ParkingAvailabilityColumn)`
	b {
		color: ${Color.PrimaryBlack};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		${StyledColumnHeader} {
			display: none;
		}
	}
`;

const StyledMobileRow = styled(UnstyledLi)<{ isSpaceBetween?: boolean }>`
	display: flex;
	flex-direction: row;
	gap: 23px;
	justify-content: ${({ isSpaceBetween }) =>
		isSpaceBetween ? 'space-between' : 'flex-start'};
`;

const StyledMobileColumn = styled(UnstyledLi)`
	display: flex;
	flex-direction: column;
	padding: 16px 12px;
	gap: 12px;
	background-color: ${Color.PrimaryWhite};
	border-radius: 8px;
	margin-bottom: 2px;
`;

const StyledTerminalName = styled.h5`
	color: ${Color.PrimaryBlack};
	font-weight: 700;
	font-size: 18px;
`;
