import {
	IParkingAvailabilityResponse,
	ISkydiveParkingAvailability,
} from '../../../skydive/types/ISkydiveParkingAvailabilityResponse';
import { IParkingRatesResponse } from '../../../skydive/types/ISkydiveParkingRatesResponse';
import { IParkingLocation } from '../types';
import { ParkingAvailabilityType } from '../types/ParkingAvailabilityType';

export const adaptParkingLocations = ({
	availability: {
		data: { parkingAvailability },
	},
	rates: {
		data: { rates },
	},
}: {
	availability: IParkingAvailabilityResponse;
	rates: IParkingRatesResponse;
}): IParkingLocation[] => {
	const terminals = parkingAvailability.filter(
		(item) => item.category === 'parent',
	);
	const sections = parkingAvailability.filter(
		(item) => item.category === 'child',
	);

	return terminals.map((terminal) => {
		const associatedSection = sections.filter(
			(section) => section.terminalId === terminal.id,
		);
		const foundRate = rates.find(({ id }) => id === terminal.id)?.rate;

		return {
			...adaptBase(terminal),
			rate: foundRate ? `*$${foundRate}/day` : '',
			sections: associatedSection.map(adaptSection),
			lastUpdatedTimestamp: terminal.lastUpdatedTimestamp,
		};
	});
};

function adaptSection(item: ISkydiveParkingAvailability) {
	return {
		...adaptBase(item),
		elevator: item.attributes.elevator !== 'No',
	};
}

function getTypeFromTier(tier: string) {
	switch (tier) {
		case 'Express':
			return ParkingAvailabilityType.EXPRESS;
		case 'Remote':
			return ParkingAvailabilityType.REMOTE;
		case 'Valet':
			return ParkingAvailabilityType.VALET;
		default:
			return ParkingAvailabilityType.TERMINAL;
	}
}

function calculateOccupancy(item: ISkydiveParkingAvailability) {
	const { available, total } = item;
	if (typeof available === 'undefined' || !total) return 0;
	return Math.round(100 - (available / total) * 100);
}

function adaptBase(item: ISkydiveParkingAvailability) {
	return {
		occupancyPercentage: calculateOccupancy(item),
		rawOccupancyPercentage:
			typeof item.available !== 'undefined' && item.total
				? 100 - (item.available / item.total) * 100
				: 0,
		name: item.name,
		chargingAvailable: item.attributes.ev !== 'No',
		open: item.status === 'Open',
		type: getTypeFromTier(item.tier),
	};
}
