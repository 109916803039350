import React from 'react';
import {
	Responsive,
	Shimmer,
	ShimmerPlaceholderText,
	UnstyledLi,
} from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import { IParkingLocation } from '../../types';
import {
	ParkingAvailabilityColumn,
	StyledColumnHeader,
} from './ParkingAvailabilityColumn';
import { Color } from '../../../../styles/Color';
import { ParkingSectionRow } from './ParkingSectionRow';

interface IParkingAvailabilityRowProps {
	parkingLocation: IParkingLocation;
}

export const ParkingAvailabilityRow = ({
	parkingLocation: { name, rate, sections },
}: IParkingAvailabilityRowProps) => (
	<>
		<StyledRowHeader>
			<TerminalName>{name}</TerminalName> <Rate>{rate}</Rate>
		</StyledRowHeader>
		<StyledChildRows>
			{sections?.map((section) => (
				<ParkingSectionRow key={section.name} parkingSection={section} />
			))}
		</StyledChildRows>
	</>
);

export const ParkingAvailabilityRowShimmer = () => (
	<Shimmer aria-label="shimmer">
		<StyledShimmerRow open={false}>
			<StyledShimmerLocationColumn width="200px">
				<ShimmerPlaceholderText />
				<ShimmerPlaceholderText />
			</StyledShimmerLocationColumn>
			<StyledShimmerLocationColumn>
				<ShimmerPlaceholderText />
				<ShimmerPlaceholderText />
			</StyledShimmerLocationColumn>
			<StyledShimmerLocationColumn>
				<ShimmerPlaceholderText />
				<ShimmerPlaceholderText />
			</StyledShimmerLocationColumn>
			<ShimmerPlaceholderText />
		</StyledShimmerRow>
	</Shimmer>
);

const StyledChildRows = styled.div`
	margin-bottom: 24px;
`;

const TerminalName = styled.h5`
	font-size: 18px;
	font-weight: 700;
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		font-weight: 400;
		margin-bottom: 8px;
	}
`;
const Rate = styled.h5`
	font-size: 18px;
	font-weight: 700;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		font-weight: 400;
	}
`;

const StyledRowHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const StyledRow = styled(UnstyledLi)<{ open: boolean }>`
	display: flex;
	margin: 0 0 16px;
	border-radius: 8px;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.11);
	background-color: ${Color.PrimaryWhite};
	position: relative;
	${({ open }) =>
		open &&
		`
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			margin-bottom: 0px;
	`}
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		flex-direction: column;
		padding: 16px;
	}
`;

const StyledLocationColumn = styled(ParkingAvailabilityColumn)`
	font-size: 24px;
	font-weight: bold;
	color: ${Color.PrimaryBlack};

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		margin-bottom: 8px;

		${StyledColumnHeader} {
			display: none;
		}
	}
`;

const StyledShimmerRow = styled(StyledRow)`
	gap: 24px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: center;
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: flex;
		flex-direction: row;

		& > ${ShimmerPlaceholderText} {
			display: none;
		}
		${ShimmerPlaceholderText} {
			width: 100%;
		}
	}
`;

const StyledShimmerLocationColumn = styled(StyledLocationColumn)<{
	width?: string;
}>`
	width: ${({ width }) => width ?? '100%'};
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: inline-block;
		width: 100%;
		&:nth-of-type(-n + 1) {
			display: none;
		}
	}
	div {
		gap: 8px;
		display: flex;
		flex-direction: column;
	}
`;
