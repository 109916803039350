import React from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Responsive } from '@m2/blueprint-ui';
import { ParkingAvailabilityColumn } from './ParkingAvailabilityColumn';
import Charging from '../../../../assets/icons/charging.svg';
import { Color } from '../../../../styles/Color';

interface IParkingAvailabilityChargingProps {
	chargingAvailable: boolean;
}

export const ParkingAvailabilityCharging = ({
	chargingAvailable,
}: IParkingAvailabilityChargingProps) => (
	<StyledParkingAvailabilityColumn
		isChargingAvailable={chargingAvailable}
		header="EV Charging"
	>
		<StyledChargingWrapper>
			<StyledCharging role="img" />
			{chargingAvailable ? 'Available' : 'No'}
		</StyledChargingWrapper>
	</StyledParkingAvailabilityColumn>
);

const StyledParkingAvailabilityColumn = styled(ParkingAvailabilityColumn)<{
	isChargingAvailable: boolean;
}>`
	opacity: ${(props) => (props.isChargingAvailable ? '1' : '0')};
	white-space: nowrap;

	svg {
		path {
			fill: ${Color.PrimaryDarkGray100} !important;
		}
	}
`;

const StyledChargingWrapper = styled.div`
	margin-top: 7px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		margin-top: 4px;
	}
`;

const StyledCharging = styled(Charging, {
	shouldForwardProp: (prop) => isPropValid(prop),
})`
	vertical-align: bottom;
	margin-right: 6px;
`;
