import React from 'react';
import styled from '@emotion/styled';
import { Markdown, Responsive } from '@m2/blueprint-ui';
import { Color } from '../../../styles/Color';

interface IParkingAvailabilityLegendProps {
	title: string;
	isLoading: boolean;
	description?: string;
	alertMessage: {
		body: string;
		title: string;
	} | null;
	lastUpdated?: string;
}

export const ParkingAvailabilitySidebar = ({
	title,
	description,
	alertMessage,
	lastUpdated,
	isLoading,
}: IParkingAvailabilityLegendProps) => (
	// Extra div needed to make ParkingAvailabilityLegend sticky
	<div>
		<StyledParkingAvailabilityLegend>
			<StyledHeader>{title}</StyledHeader>
			{!isLoading && lastUpdated && (
				<StyledLastUpdated>
					Updated at: <em>{lastUpdated}</em>
				</StyledLastUpdated>
			)}
			<Markdown source={description ?? ''} />
			{alertMessage && (
				<StyledParkingAvailabilityAlert>
					<StyledAlertTitle>{alertMessage.title}</StyledAlertTitle>
					<p>{alertMessage.body}</p>
				</StyledParkingAvailabilityAlert>
			)}
		</StyledParkingAvailabilityLegend>
	</div>
);

export const StyledParkingAvailabilityLegend = styled.div`
	position: sticky;
	top: 80px;

	p {
		color: ${Color.PrimaryBlack};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		position: relative;
		top: 0;
		padding-top: 42px;
	}
`;

const StyledParkingAvailabilityAlert = styled.div`
	background-color: ${Color.SecondaryYellow50};
	padding: 12px 16px 12px 16px;
	border-radius: 8px;
	gap: 4px;
`;

const StyledAlertTitle = styled.h5`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

const StyledHeader = styled.h2`
	font-weight: 400;
	font-size: 32px;
`;

const StyledLastUpdated = styled.div`
	color: ${Color.PrimaryBlack};
	margin-bottom: 12px;
	font-weight: 400;
	em {
		font-style: normal;
		color: ${Color.PrimaryOrange100};
	}
`;
