import React, { MouseEvent } from 'react';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import { getResponsiveImageUrl, IImageOptions } from '../../../utils';

export const HIGHLIGHT_ITEM_WIDTH = 260;
export const HIGHLIGHT_ITEM_HEIGHT = 360;
export const PREVIEW_ITEM_HEIGHT = 320;
export const PREVIEW_ITEM_WIDTH = 240;

export const ITEM_WIDTH_MOBILE = 220;
export const PREVIEW_ITEM_HEIGHT_MOBILE = 220;
export const HIGHLIGHT_ITEM_HEIGHT_MOBILE = 260;

interface ICarouselItemProps {
	title: string;
	imageSrc: string;
	iconSrc?: string;
	isHighlighted?: boolean;
	onClick: (event: MouseEvent<HTMLDivElement>) => void;
	onMouseEnter?: (event: MouseEvent<HTMLDivElement>) => void;
	onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const CarouselItem = ({
	title,
	imageSrc,
	iconSrc,
	onClick,
	onMouseEnter,
	onMouseLeave,
	isHighlighted = false,
}: ICarouselItemProps) => (
	<StyledCarouselItem
		imageSrc={imageSrc}
		isHighlighted={isHighlighted}
		onClick={(e) => onClick(e)}
		onMouseEnter={(e) => onMouseEnter && onMouseEnter(e)}
		onMouseLeave={(e) => onMouseLeave && onMouseLeave(e)}
	>
		{iconSrc && <StyledIcon src={iconSrc} alt="" />}
		<StyledCarouselItemTitle>{title}</StyledCarouselItemTitle>
	</StyledCarouselItem>
);

export const CarouselPlaceholderItem = () => <StyledCarouselItemPlaceholder />;

export interface IStyledCarouselItemProps {
	imageSrc: string;
	isHighlighted?: boolean;
}

export const StyledIcon = styled.img`
	width: 32px;
	height: 32px;
	margin-bottom: 12px;
`;

export const StyledCarouselItemTitle = styled.p`
	display: flex;
	font-weight: bold;
	font-size: 18px;
	color: white;
	text-align: center;
`;

export const StyledCarouselItem = styled.div<IStyledCarouselItemProps>`
	display: flex;
	flex-direction: column;
	font-weight: bold;
	font-size: 18px;
	color: white;
	height: ${PREVIEW_ITEM_HEIGHT}px;
	border: 0;
	border-radius: 8px;
	margin-left: 20px;
	margin-right: 20px;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;
	padding-bottom: 24px;
	overflow: hidden;
	opacity: 0.6;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
	background-image: ${({ imageSrc }) => `url(${getDesktopImageUrl(imageSrc)})`};
	background-size: cover;
	transition: height 0.2s, opacity 0.1s;

	flex: 0 0 ${PREVIEW_ITEM_WIDTH}px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		flex: 0 0 ${PREVIEW_ITEM_WIDTH - 20}px;
		height: ${PREVIEW_ITEM_HEIGHT_MOBILE}px;
		background-image: ${({ imageSrc }) =>
			`url(${getMobileImageUrl(imageSrc)})`};
	}

	${({ isHighlighted }) =>
		isHighlighted &&
		`
			height: ${HIGHLIGHT_ITEM_HEIGHT}px;
			padding: 24px;
			opacity: 1;

			@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
				height: ${HIGHLIGHT_ITEM_HEIGHT_MOBILE}px;
			}

			${StyledCarouselItemTitle} {
				font-weight: bold;
				font-size: 24px;
			}
	`}
`;

export const StyledCarouselItemPlaceholder = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0 0 50px;
	height: ${PREVIEW_ITEM_HEIGHT}px;
	color: white;
	border: 0;
	border-radius: 8px;
	margin-left: 20px;
	margin-right: 20px;
	justify-content: flex-end;
	align-items: center;
	padding-bottom: 24px;
	flex-shrink: 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		flex: 0 0 30px;
		height: ${PREVIEW_ITEM_HEIGHT_MOBILE}px;
		margin-left: 0;
	}
`;

const IMAGE_OPTIONS: IImageOptions = {
	fit: 'thumb',
};

function getDesktopImageUrl(imageUrl: string): string {
	return getResponsiveImageUrl(imageUrl, {
		...IMAGE_OPTIONS,
		w: ITEM_WIDTH_MOBILE * 2,
		h: HIGHLIGHT_ITEM_HEIGHT_MOBILE * 2,
	});
}

function getMobileImageUrl(imageUrl: string): string {
	return getResponsiveImageUrl(imageUrl, {
		...IMAGE_OPTIONS,
		w: HIGHLIGHT_ITEM_WIDTH * 2,
		h: HIGHLIGHT_ITEM_HEIGHT * 2,
	});
}
