import React from 'react';
import log from 'loglevel';
import { TextSection } from './components/TextSection';
import { CallToActionSection } from './components/CallToActionSection';
import {
	ACCORDION_SECTION_TYPE_ID,
	CALL_TO_ACTION_SECTION_TYPE_ID,
	CAROUSEL_SECTION_TYPE_ID,
	HIGHLIGHT_SECTION_TYPE_ID,
	NEWS_SECTION_TYPE_ID,
	TAB_SECTION_TYPE_ID,
	COLUMN_SECTION_TYPE_ID,
	QUICK_LINKS_SECTION_TYPE_ID,
	TEXT_SECTION_TYPE_ID,
	CARD_SECTION_TYPE_ID,
	CONTACT_SECTION_TYPE_ID,
	PHOTO_GALLERY_SECTION_TYPE_ID,
	IFRAME_SECTION_TYPE_ID,
	PARKING_AVAILABILITY_SECTION_TYPE_ID,
} from '../../../contentful/constants/contentTypes';

import { IPageSectionEntry } from '../../../contentful/types/IPageSectionEntry';
import { CarouselSection } from './components/CarouselSection';
import { HighlightSection } from './components/HighlightSection';
import { NewsSection } from './components/NewsSection';
import { TabSection } from './components/TabSection';
import { AccordionSection } from './components/AccordionSection';
import { ColumnSection } from './components/ColumnSection';
import { CardSection } from './components/CardSection';
import { ContactSection } from './components/ContactSection';
import { PhotoGallerySection } from './components/PhotoGallerySection';
import { QuickLinksSection } from './components/QuickLinksSection';
import { IFrameSection } from './components/IFrameSection';
import { ParkingAvailabilitySection } from './components/ParkingAvailabilitySection';

export function renderSectionComponent(section: IPageSectionEntry) {
	switch (section.contentType) {
		case CALL_TO_ACTION_SECTION_TYPE_ID:
			return <CallToActionSection section={section} key={section.id} />;

		case TEXT_SECTION_TYPE_ID:
			return <TextSection section={section} key={section.id} />;

		case CAROUSEL_SECTION_TYPE_ID:
			return <CarouselSection section={section} key={section.id} />;

		case HIGHLIGHT_SECTION_TYPE_ID:
			return <HighlightSection section={section} key={section.id} />;

		case NEWS_SECTION_TYPE_ID:
			return <NewsSection section={section} key={section.id} />;

		case TAB_SECTION_TYPE_ID:
			return <TabSection section={section} key={section.id} />;

		case ACCORDION_SECTION_TYPE_ID:
			return <AccordionSection section={section} key={section.id} />;

		case COLUMN_SECTION_TYPE_ID:
			return <ColumnSection section={section} key={section.id} />;

		case CARD_SECTION_TYPE_ID:
			return <CardSection section={section} key={section.id} />;

		case CONTACT_SECTION_TYPE_ID:
			return <ContactSection section={section} key={section.id} />;

		case PHOTO_GALLERY_SECTION_TYPE_ID:
			return <PhotoGallerySection section={section} key={section.id} />;

		case QUICK_LINKS_SECTION_TYPE_ID:
			return <QuickLinksSection section={section} key={section.id} />;

		case IFRAME_SECTION_TYPE_ID:
			return <IFrameSection section={section} key={section.id} />;

		case PARKING_AVAILABILITY_SECTION_TYPE_ID:
			return <ParkingAvailabilitySection section={section} key={section.id} />;

		default:
			const invalidSection = section;

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			log.error(`Unknown content type: ${(invalidSection as any).contentType}`);
	}

	return null;
}
