import React from 'react';
import styled from '@emotion/styled';

import { Responsive } from '@m2/blueprint-ui';
import { Section } from '../Section';
import { NewsCarouselContainer } from '../../news-carousel';
import { INewsSectionEntry } from '../../../../contentful';
import { Color } from '../../../../styles/Color';

export interface INewsSectionProps {
	section: INewsSectionEntry;
}

export const NewsSection = ({ section }: INewsSectionProps) => (
	<Section>
		<DesktopWrapper>
			<ImageLeftBackgroundPanel />
			<NewsCarouselContainer section={section} />
		</DesktopWrapper>
		<MobileWrapper>
			<StyledSectionWrapper>
				<ImageLeftBackgroundPanelMobile />
				<NewsCarouselContainer section={section} />
			</StyledSectionWrapper>
		</MobileWrapper>
	</Section>
);

const ImageLeftBackgroundPanelMobile = styled.div`
	position: absolute;
	width: 100vw;
	height: 100%;
	display: block;
	transform: translateX(-16px);
	background-color: ${Color.PrimaryLightGray};
	z-index: -2;
`;

const DesktopWrapper = styled.div`
	display: flex;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

const MobileWrapper = styled.div`
	display: none;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: flex;
	}
`;

const StyledSectionWrapper = styled.div`
	display: flex;
	flex-direction: row;

	position: relative;
`;

const ImageLeftBackgroundPanel = styled.div`
	position: absolute;
	left: 0px;
	margin-top: 40px;
	height: 555px;
	width: 300px;
	display: block;
	z-index: -1;
	background-color: ${Color.PrimaryLightGray};

	@media (${Responsive.getMediaQueryForBreakpoint('desktopXL')}) {
		width: 32%;
	}

	@media (max-width: 1800px) {
		width: 600px;
	}

	@media (max-width: 1600px) {
		width: 400px;
	}
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 200px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;
