import {
	IAssetLinkItem,
	IEmailLinkItem,
	IExternalLinkItem,
	IInternalLinkItem,
	IPhoneLinkItem,
} from '@m2/blueprint-ui';
import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import { getLinkItemForEntry } from './getLinkItemForEntry';
import { ILinkEntry } from '../../../../contentful/types';

type ILinkItem =
	| IAssetLinkItem
	| IEmailLinkItem
	| IExternalLinkItem
	| IInternalLinkItem
	| IPhoneLinkItem;

export function getLinkItemsForEntries<T extends ILinkItem>(
	entries: ILinkEntry[],
	pathToPageIdMap: IPathToPageIdMap,
) {
	if (!entries || !entries.length) {
		return [];
	}

	const items: T[] = [];

	entries.forEach((entry, index) => {
		const item = getLinkItemForEntry(entry, index, pathToPageIdMap);

		if (item) {
			items.push(item as T);
		}
	});

	return items;
}
