import React from 'react';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import { ParkingOccupancy } from '../types';
import { Color } from '../../../styles/Color';

const barColors: {
	[key in ParkingOccupancy]: Color;
} = {
	[ParkingOccupancy.All]: Color.SecondaryGreen30,
	[ParkingOccupancy.Low]: Color.UtilityR300,
	[ParkingOccupancy.Middle]: Color.UtilityO600,
	[ParkingOccupancy.None]: Color.PrimaryLightGray,
};

const getProgressBarLevel = (progress: number): ParkingOccupancy => {
	if (progress === 0) {
		return ParkingOccupancy.None;
	}
	if (progress >= 85 && progress <= 92) {
		return ParkingOccupancy.Middle;
	}
	if (progress >= 92 && progress <= 100) {
		return ParkingOccupancy.Low;
	}
	return ParkingOccupancy.All;
};

interface IIndicatorIconsProps {
	occupancyPercentage: number;
	rawOccupancyPercentage: number;
}

export const ParkingOccupancyIndicator = ({
	occupancyPercentage,
	rawOccupancyPercentage,
}: IIndicatorIconsProps) => {
	const color = barColors[getProgressBarLevel(rawOccupancyPercentage)];

	return (
		<StyledIndicatorIcons>
			<StyledProgressWrapper>
				<StyledPercentageText>{occupancyPercentage}% Full</StyledPercentageText>
				<StyledProgressBar
					aria-valuenow={occupancyPercentage}
					role="progressbar"
					width={occupancyPercentage}
					color={color}
				/>
			</StyledProgressWrapper>
		</StyledIndicatorIcons>
	);
};

const StyledIndicatorIcons = styled.div`
	white-space: nowrap;
`;

const StyledProgressWrapper = styled.div`
	display: flex;
	gap: 8px;
`;

const StyledProgressBar = styled.div<{ width: number; color: string }>`
	background-color: ${Color.PrimaryLightGray};
	height: 20px;
	width: 100%;
	max-width: 120px;
	margin-top: 7px;
	margin-right: 4px;
	position: relative;
	overflow: hidden;
	border-radius: 4px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		height: 12px;
		margin-top: 12px;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: ${({ width }) => width}%;
		background-color: ${({ color }) => color};
	}
`;

const StyledPercentageText = styled.span`
	font-size: 16px;
	color: ${Color.PrimaryBlack};
	display: none;
`;
