import log from 'loglevel';
import {
	IAssetLinkItem,
	IEmailLinkItem,
	IExternalLinkItem,
	IInternalLinkItem,
	IPhoneLinkItem,
} from '@m2/blueprint-ui';
import { INTERNAL_LINK_TYPE_ID } from '@m2/blueprint-contentful';
import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import {
	PHONE_LINK_TYPE_ID,
	EMAIL_LINK_TYPE_ID,
	EXTERNAL_LINK_TYPE_ID,
	ASSET_LINK_TYPE_ID,
} from '../../../../contentful/constants';
import { getListedEntryKey } from '../../../../utils';
import { getUrlForLink } from '../../../../navigation';
import { ILinkEntry } from '../../../../contentful';

export function getLinkItemForEntry(
	entry: ILinkEntry,
	index: number,
	pathToPageIdMap: IPathToPageIdMap,
) {
	const key = getListedEntryKey(entry, index);

	switch (entry.contentType) {
		case ASSET_LINK_TYPE_ID:
			if (!entry.fields.asset) {
				return undefined;
			}

			const assetLink: IAssetLinkItem = {
				key,
				type: 'asset-link',
				title: entry.fields.title,
				url: entry.fields.asset.fields.file.url,
			};
			return assetLink;

		case EMAIL_LINK_TYPE_ID:
			const emailLink: IEmailLinkItem = {
				key,
				type: 'email-link',
				...entry.fields,
			};
			return emailLink;

		case EXTERNAL_LINK_TYPE_ID:
			const externalLinkUrl = getUrlForLink(entry, pathToPageIdMap);

			if (!externalLinkUrl) {
				return undefined;
			}

			const externalLink: IExternalLinkItem = {
				key,
				type: 'external-link',
				title: entry.fields.title,
				url: externalLinkUrl as string,
			};
			return externalLink;

		case INTERNAL_LINK_TYPE_ID:
			const internalLinkUrl = getUrlForLink(entry, pathToPageIdMap);

			if (!internalLinkUrl) {
				return undefined;
			}

			const internalLink: IInternalLinkItem = {
				key,
				type: 'internal-link',
				title: entry.fields.title,
				url: internalLinkUrl,
			};
			return internalLink;

		case PHONE_LINK_TYPE_ID:
			const phoneLink: IPhoneLinkItem = {
				key,
				type: 'phone-link',
				...entry.fields,
			};
			return phoneLink;

		default:
			const invalidEntry: never = entry;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			log.error(`Invalid asset link type ${(invalidEntry as any).contentType}`);
	}

	return undefined;
}
