import React from 'react';
import styled from '@emotion/styled';
import {
	StyledHighlight,
	StyledHighlightContent,
	StyledIconLink,
	StyledLinkText,
	Responsive,
	LinkButton,
	StyledLinkButton,
	Markdown,
} from '@m2/blueprint-ui';
import {
	IHighlightSectionEntry,
	HighlightLinkEntry,
} from '../../../contentful';
import { getUrlForLink } from '../../../navigation';
import { useCommonData } from '../../../common-data/useCommonData';
import { Color } from '../../../styles/Color';

interface IHighlightSectionProps {
	section: IHighlightSectionEntry;
}

interface IProps {
	imageLeft?: boolean;
}

export const CustomHighlightSectionDesktop = styled.div<IProps>`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 40vw;
	}

	border-radius: 8px;
	padding: 40px;
	padding-left: ${({ imageLeft }) => (imageLeft ? '60px' : '0')};
	width: 30vw;
	max-width: 520px;
	margin-left: ${({ imageLeft }) => (imageLeft ? '0' : '24px')};
	margin-right: 0px;
	display: flex;
	flex-direction: column;
	background-color: ${Color.PrimaryLightGray};
	min-height: 371px;
	justify-content: center;

	${StyledLinkButton} {
		width: fit-content;
	}
`;

export const Highlight = ({ section }: IHighlightSectionProps) => {
	const { pathToPageIdMap } = useCommonData();
	const { title, subtitle, body, links, icon, imagePosition } = section.fields;

	return (
		<>
			<CustomHighlightSectionDesktop imageLeft={imagePosition === 'left'}>
				{subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
				<StyledTitle>{title}</StyledTitle>
				<StyledBody hasLink={!!links}>
					{body && <Markdown source={body} />}
				</StyledBody>
				<LinkWrapper>
					{links &&
						links.map((link: HighlightLinkEntry) => (
							<LinkButton
								key={link.id}
								href={getUrlForLink(link, pathToPageIdMap)}
							>
								{link.fields.title}
							</LinkButton>
						))}
				</LinkWrapper>
			</CustomHighlightSectionDesktop>

			<CustomMobileHighlight orientation="horizontal">
				<StyledMobileTitleWrapper>
					{icon && <StyledImage src={icon.fields.file.url} alt="" />}
					<h2>{title}</h2>
				</StyledMobileTitleWrapper>
				{subtitle && <h3>{subtitle}</h3>}
				{body && <Markdown source={body} />}
				<MobileLinkWrapper>
					{links &&
						links.map((link: HighlightLinkEntry) => (
							<LinkButton
								key={link.id}
								href={getUrlForLink(link, pathToPageIdMap)}
							>
								{link.fields.title}
							</LinkButton>
						))}
				</MobileLinkWrapper>
			</CustomMobileHighlight>
		</>
	);
};

export const LinkWrapper = styled.div`
	display: flex;
	flex-direction: row;
	overflow: hidden;

	button:first-of-type {
		margin-right: 24px;
	}

	button {
		max-width: 45%;

		@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
			max-width: 40%;
		}
	}
`;

export const MobileLinkWrapper = styled.div`
	display: flex;
	flex-direction: column;
	button:first-of-type {
		margin-bottom: 12px;
	}
`;

export const StyledSubtitle = styled.h2`
	margin-bottom: 0;
	font-size: 18px;
	font-weight: bold;
	color: ${Color.PrimaryOrange100};
	line-height: normal;
`;

export const StyledTitle = styled.h2`
	margin-bottom: 8px;
	font-size: 24px;
	font-weight: normal;
	color: ${Color.PrimaryDarkGray100};
	line-height: normal;
`;

export const StyledBody = styled.div<{ hasLink: boolean }>`
	min-width: 0;
	margin-bottom: ${({ hasLink }) => (hasLink ? '30px' : '0')};

	p {
		font-size: 16px;
		font-weight: normal;
		color: ${Color.PrimaryDarkGray100};
		line-height: normal;
	}
`;

export const StyledImage = styled.img`
	display: flex;
	width: 24px;
	height: 24px;
	margin-right: 8px;
`;

export const StyledMobileTitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0;

	h2 {
		margin-bottom: 0;
	}
`;

export const CustomMobileHighlight = styled.div`
	display: none;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: block;
	}

	${StyledHighlight} {
		box-shadow: 0;
	}

	h2 {
		font-size: 18px;
		font-weight: bold;
		color: ${Color.PrimaryOrange100};
		line-height: 1.9;
	}

	h3 {
		font-size: 18px;
		font-weight: normal;
		color: ${Color.PrimaryDarkGray100};
		margin-bottom: 8px;
	}

	p {
		font-size: 16px;
		font-weight: normal;
		color: ${Color.PrimaryDarkGray100};
		line-height: 1.63;
	}

	${StyledIconLink} {
		background-color: ${Color.PrimaryOrange100};
		padding: 11px 24px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;

		:hover:after {
			width: 0px;
			color: ${Color.PrimaryLightGray};
		}

		svg {
			display: none;
		}

		${StyledLinkText} {
			color: white;
			font-weight: bold;
		}
	}

	border-radius: 8px;
	width: 100%;
	margin-right: 0px;

	${StyledHighlightContent} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 100%;

		padding: 0px;

		p:first-of-type {
			margin-bottom: 20px;
		}
	}
`;
