import { IParkingLocation } from '../types';

export const getLatestUpdatedTimestamp = (
	locations: IParkingLocation[],
): number =>
	locations.reduce((latestUpdatedTimestamp, location) => {
		if (location.lastUpdatedTimestamp > latestUpdatedTimestamp) {
			return location.lastUpdatedTimestamp;
		}

		return latestUpdatedTimestamp;
	}, 0);
