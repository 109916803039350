import React from 'react';
import { ICallToActionLink } from '@m2/blueprint-ui';
import { Section } from '../Section';
import { ICallToActionSectionEntry } from '../../../../contentful';
import { CallToAction } from '../../call-to-action';
import { getLinkItemsForEntries } from '../mappers';
import { useCommonData } from '../../../../common-data/useCommonData';

export interface ICallToActionsSectionProps {
	section: ICallToActionSectionEntry;
}

export const CallToActionSection = ({
	section,
}: ICallToActionsSectionProps) => {
	const { pathToPageIdMap } = useCommonData();
	const links = getLinkItemsForEntries<ICallToActionLink>(
		section.fields.links,
		pathToPageIdMap,
	);

	return (
		<Section>
			<CallToAction
				callToActionTheme={section.fields.style}
				title={section.fields.title}
				body={section.fields.body}
				links={links}
			/>
		</Section>
	);
};
