import React from 'react';
import styled from '@emotion/styled';
import { ArrowLink, Responsive } from '@m2/blueprint-ui';
import { IAccordionSectionEntry } from '../../../../contentful/types/IAccordionSectionEntry';
import { AccordionItem } from '../../accordion/AccordionItem';
import { Accordion } from '../../accordion/Accordion';
import { Section } from '../Section';
import { IAccordionItemEntry } from '../../../../contentful/types/IAccordionItemEntry';
import { Color } from '../../../../styles/Color';
import { getUrlForLink } from '../../../../navigation';
import { useCommonData } from '../../../../common-data/useCommonData';

export interface IAccordionSectionProps {
	section: IAccordionSectionEntry;
}

export const AccordionSection = ({ section }: IAccordionSectionProps) => {
	const { pathToPageIdMap } = useCommonData();
	return (
		<Section>
			{section.fields.title && (
				<StyledHeadingWrapper>
					<h1>{section.fields.title}</h1>
				</StyledHeadingWrapper>
			)}
			<Accordion>
				{section.fields.items.map(({ id, fields }: IAccordionItemEntry) => (
					<AccordionItem key={id} title={fields.title} body={fields.body} />
				))}
				{section.fields.readMoreLink && (
					<StyledLinkWrapper>
						<ArrowLink
							href={
								getUrlForLink(
									section.fields.readMoreLink,
									pathToPageIdMap,
								) as string
							}
						>
							{section.fields.readMoreLink.fields.title}
						</ArrowLink>
					</StyledLinkWrapper>
				)}
			</Accordion>
		</Section>
	);
};

export const StyledLinkWrapper = styled.div`
	margin-top: 16px;
`;

export const StyledHeadingWrapper = styled.div`
	h1 {
		font-weight: normal;
		line-height: normal;
		color: ${Color.PrimaryDarkGray100};
		font-size: 32px;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			font-size: 24px;
		}
	}
`;
