import { getSkydiveClient } from '../../../skydive';
import { adaptParkingLocations } from '../helpers/adaptParkingLocations';

export const getParkingLocations = async () => {
	const api = getSkydiveClient();
	const [availability, rates] = await Promise.all([
		api.parkingAvailability().get(),
		api.parkingRates().get(),
	]);
	return adaptParkingLocations({ availability, rates });
};
