import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import React from 'react';
import { IHighlightSectionEntry } from '../../../../contentful';
import { Color } from '../../../../styles/Color';
import { getResponsiveImageUrl } from '../../../../utils';
import { Highlight } from '../../highlight/Highlight';

import { Section } from '../Section';

export interface IHighlightSectionProps {
	section: IHighlightSectionEntry;
	className?: string;
}

export const HighlightSection = ({
	section,
	className,
}: IHighlightSectionProps) => {
	const imageBaseUrl = section.fields.image?.fields.file?.url;

	const imageDesktopUrl =
		imageBaseUrl &&
		getResponsiveImageUrl(imageBaseUrl, {
			w: 1200,
			h: 700,
			fit: 'fill',
		});

	const imageMobileUrl =
		imageBaseUrl &&
		getResponsiveImageUrl(imageBaseUrl, {
			w: 800,
			h: 470,
			fit: 'fill',
		});

	return (
		<Section className={className}>
			<DesktopWrapper>
				<StyledSectionWrapper>
					{section.fields.imagePosition === 'left' && (
						<>
							<ImageLeftBackgroundPanel />
							{imageDesktopUrl && (
								<StyledImageWrapperLeft src={imageDesktopUrl} alt="" />
							)}
							<Highlight section={section} />
						</>
					)}
					{section.fields.imagePosition === 'right' && (
						<>
							<Highlight section={section} />
							<ImageRightBackgroundPanel />
							{imageDesktopUrl && (
								<StyledImageWrapperRight src={imageDesktopUrl} alt="" />
							)}
						</>
					)}
				</StyledSectionWrapper>
			</DesktopWrapper>
			<MobileWrapper>
				{imageMobileUrl && <StyledImageWrapperMobile src={imageMobileUrl} />}
				<Highlight section={section} />
			</MobileWrapper>
		</Section>
	);
};

export const DesktopWrapper = styled.div`
	display: flex;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

export const MobileWrapper = styled.div`
	display: none;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: block;
	}
`;

export const ImageLeftBackgroundPanel = styled.div`
	position: absolute;
	width: 52vw;
	display: block;
	right: -50vw;
	background-color: ${Color.PrimaryLightGray};
	z-index: -1;
	top: 0;
	bottom: 0;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		right: -20vw;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		right: -20vw;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

export const ImageRightBackgroundPanel = styled.div`
	position: absolute;
	width: 68vw;
	display: block;
	left: -60vw;
	top: 0;
	bottom: 0;
	z-index: -1;
	background-color: ${Color.PrimaryLightGray};
`;

export const StyledSectionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
`;

export const StyledImageWrapperRight = styled.img`
	width: 500px;
	max-width: 40vw;
	border-radius: 8px;
	margin-left: 80px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		margin-left: 40px;
	}
`;

export const StyledImageWrapperLeft = styled.img`
	width: 500px;
	max-width: 40vw;
	border-radius: 8px;
	margin-right: 80px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		margin-right: 40px;
	}
`;

export const StyledImageWrapperMobile = styled.img`
	display: block;
	max-width: 100%;
	border-radius: 8px;
	margin-bottom: 24px;
`;
