import React from 'react';
import { observer } from 'mobx-react';
import { Responsive } from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import log from 'loglevel';
import { format, fromUnixTime } from 'date-fns';
import { LocationStore } from '../stores/LocationStore';
import { ParkingAvailabilityFilter } from './ParkingAvailabilityFilter';
import { IParkingAvailabilitySection } from '../../../contentful/types/IParkingAvailabilitySectionEntry';
import { ParkingAvailabilityType } from '../types/ParkingAvailabilityType';
import {
	ParkingAvailabilitySidebar,
	StyledParkingAvailabilityLegend,
} from './ParkingAvailabilitySidebar';
import { ParkingAvailabilityLocations } from './ParkingAvailabilityLocations';
import { Color } from '../../../styles/Color';
import { getLatestUpdatedTimestamp } from '../helpers/getLatestUpdatedTimestamp';
import { ParkingAvailabilityError } from './ParkingAvailabilityError';

interface IParkingAvailabilityProps {
	store: LocationStore;
	section: IParkingAvailabilitySection;
}

export const ParkingAvailability = observer(
	({ store, section }: IParkingAvailabilityProps) => {
		const isTablet = Responsive.useMatchesBreakpoint('tablet');
		const isMobileXL = Responsive.useMatchesBreakpoint('mobileXL');
		const latestUpdatedTime = fromUnixTime(
			getLatestUpdatedTimestamp(store.locations),
		);

		const lastUpdated = store.locations
			? format(latestUpdatedTime, 'hh:mm a')
			: undefined;

		const toggleFilter = (filter: ParkingAvailabilityType) => {
			store.toggleFilter(filter);
			if (isMobileXL && typeof window !== undefined && window.scrollY > 500) {
				window.scrollTo(0, 500);
			}
		};

		return (
			<>
				<StyledMain>
					{!isTablet && (
						<ParkingAvailabilitySidebar
							title={section.legendTitle}
							description={section.legendDescription}
							alertMessage={
								section.alertTitle && section.alertBody
									? {
											title: section.alertTitle,
											body: section.alertBody,
									  }
									: null
							}
							isLoading={store.isLoading}
							lastUpdated={lastUpdated}
						/>
					)}

					<StyledRight>
						<ParkingAvailabilityFilter
							onToggle={toggleFilter}
							activeType={store.filters}
							clickable={
								store.locations.length !== 0 && !store.isLoading && !store.error
							}
							getTitle={(type) => getTitle(type, section)}
						/>
						{isTablet && (
							<ParkingAvailabilitySidebar
								title={section.legendTitle}
								description={section.legendDescription}
								alertMessage={
									section.alertTitle && section.alertBody
										? {
												title: section.alertTitle,
												body: section.alertBody,
										  }
										: null
								}
								isLoading={store.isLoading}
								lastUpdated={lastUpdated}
							/>
						)}
						<StyledLocationsWrap>
							<>
								{store.error ? (
									<ParkingAvailabilityError message={section.errorMessage} />
								) : (
									Object.values(ParkingAvailabilityType).map(
										(type: ParkingAvailabilityType) => (
											<ParkingAvailabilityLocations
												key={type}
												isLoading={store.isLoading}
												locations={store.locations.filter(
													(location) => location.type === type,
												)}
												title={getTitle(type, section)}
												description={getDescription(type, section)}
												bookNowLink={getLink(type, section)}
											/>
										),
									)
								)}
							</>
						</StyledLocationsWrap>
					</StyledRight>
				</StyledMain>
			</>
		);
	},
);

const getTitle = (
	type: ParkingAvailabilityType,
	section: IParkingAvailabilitySection,
) => {
	switch (type) {
		case ParkingAvailabilityType.TERMINAL:
			return section.terminalTitle;
		case ParkingAvailabilityType.EXPRESS:
			return section.expressTitle;
		case ParkingAvailabilityType.REMOTE:
			return section.remoteTitle;
		case ParkingAvailabilityType.VALET:
			return section.valetTitle;
		default:
			log.warn('Unknown ParkingAvailabilityType', type);

			return type;
	}
};

const getDescription = (
	type: ParkingAvailabilityType,
	section: IParkingAvailabilitySection,
) => {
	switch (type) {
		case ParkingAvailabilityType.TERMINAL:
			return section.terminalDescription;
		case ParkingAvailabilityType.EXPRESS:
			return section.expressDescription;
		case ParkingAvailabilityType.REMOTE:
			return section.remoteDescription;
		case ParkingAvailabilityType.VALET:
			return section.valetDescription;
		default:
			log.warn('Unknown ParkingAvailabilityType', type);
			return '';
	}
};

const getLink = (
	type: ParkingAvailabilityType,
	section: IParkingAvailabilitySection,
) => {
	switch (type) {
		case ParkingAvailabilityType.TERMINAL:
			return section.terminalBookNowLink;
		case ParkingAvailabilityType.EXPRESS:
			return section.expressBookNowLink;
		case ParkingAvailabilityType.REMOTE:
			return section.remoteBookNowLink;
		case ParkingAvailabilityType.VALET:
			return section.valetBookNowLink;
		default:
			log.warn('Unknown ParkingAvailabilityType', type);
			return undefined;
	}
};

const StyledLocationsWrap = styled.div`
	background-color: ${Color.PrimaryLightGray50};
	border-radius: 8px;
	padding: 40px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		margin-right: 0;
		padding: 16px;
	}
`;

const StyledMain = styled.div`
	display: flex;
	margin-top: 40px;
	justify-content: center;

	${StyledParkingAvailabilityLegend} {
		min-width: 238px;
		max-width: 238px;
		margin-right: 32px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		flex-direction: column;
		margin-top: 0;
		${StyledParkingAvailabilityLegend} {
			max-width: none;
			margin-right: 0;
			margin-bottom: 40px;
			padding: 16px;
		}
	}
`;

const StyledRight = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin-top: -42px;
	max-width: 930px;
`;
