import { useEffect } from 'react';
import { locationStore } from '../stores';

export const useLocationStore = () => {
	useEffect(() => {
		locationStore.load();
		const interval = setInterval(() => locationStore.load(), 1000 * 60 * 5); // refresh every 5 minutes
		return () => {
			locationStore.reset();
			clearInterval(interval);
		};
	}, [locationStore]);
	return locationStore;
};
