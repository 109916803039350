import React from 'react';
import { Markdown } from '@m2/blueprint-ui';
import { ITextSectionEntry } from '../../../../contentful/types/ITextSectionEntry';
import { Section } from '../Section';

export interface ITextSectionsProps {
	section: ITextSectionEntry;
}

export const TextSection = ({ section }: ITextSectionsProps) => (
	<Section>
		<Markdown source={section.fields.body} />
	</Section>
);
