import {
	Responsive,
	StyledArrowLinkButton,
	StyledButtonText,
} from '@m2/blueprint-ui';
import { Color } from '../../../styles/Color';
import { ICallToActionTheme } from './ICallToActionTheme';

export function getCallToActionStyle(callToActionTheme: ICallToActionTheme) {
	return `
		h3 {
			color: ${
				callToActionTheme === 'transparent'
					? Color.PrimaryDarkGray100
					: Color.PrimaryWhite
			};
			font-weight: normal;
			font-size: 36px;
			margin-bottom: 16px;

			@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
				font-size: 24px;
				margin-bottom: 8px;
			}
		}

		p {
			color: ${
				callToActionTheme === 'transparent'
					? Color.PrimaryDarkGray100
					: Color.PrimaryWhite
			}
		}

		${StyledButtonText} {
			color: ${Color.PrimaryDarkGray100};
		}

		${StyledArrowLinkButton} {
			margin-top: 24px;
			background-color: ${
				callToActionTheme === 'transparent' ? Color.PrimaryOrange100 : ''
			};

			g {
				stroke: ${
					callToActionTheme === 'transparent'
						? Color.PrimaryWhite
						: Color.PrimaryDarkGray100
				};
			}

			${StyledButtonText} {
				color: ${
					callToActionTheme === 'transparent'
						? Color.PrimaryWhite
						: Color.PrimaryDarkGray80
				};
			}	

			:hover {
				${StyledButtonText} {
					color: ${
						callToActionTheme === 'transparent'
							? Color.PrimaryWhite
							: Color.PrimaryDarkGray80
					};
				}

				${StyledArrowLinkButton} {
					g {
						stroke: ${
							callToActionTheme === 'transparent'
								? Color.PrimaryWhite
								: Color.PrimaryDarkGray80
						};
					}
				}

				g {
					stroke: ${
						callToActionTheme === 'transparent'
							? Color.PrimaryWhite
							: Color.PrimaryDarkGray100
					};
				}
			}
		}

		border-radius: 8px;
		background: ${
			(callToActionTheme === 'blue' && Color.SecondaryBlue100) ||
			(callToActionTheme === 'green' && Color.SecondaryGreen100) ||
			(callToActionTheme === 'purple' && Color.SecondaryPurple100) ||
			(callToActionTheme === 'transparent' && 'transparent')
		}
	`;
}
